<template>
  <div>
    <FilterInput
      :filterStatus="false"
      name-label="ชื่อหน่วยงาน"
      name-input-label="ค้นหารายชื่อหน่วยงาน"
      :agency="agency"
      @handle-agency="handleAgency"
      @handle-date="handleDate"
    />
    <i-card mt-3>
      <i-header title="รายชื่อหน่วยงาน">
        <div class="action-container">
          <div class="action-button">
            <i-button @click="dialog = true">เพิ่มคูปอง home isolation</i-button>
          </div>
          <!-- <v-btn outlined color="#1E4D94" style="text-transform: none">Export file</v-btn> -->
        </div>
      </i-header>
      <Datatables :columns="columns" :url="url" />
    </i-card>
    <coupon-isolate-editable v-model="dialog" :isCreate="true" :data="{}" />
  </div>
</template>

<script>
import moment from "moment";
import { columns } from "data/tables/coupon-isolation-columns";
import Datatables from "components/datatables/index.vue";
import FilterInput from "./coupon/modules/filter-input";
import CouponIsolateEditable from "components/dialog/coupon-isolate-editable.vue";
export default {
  data: () => ({
    columns,
    startDate: null,
    days: 1,
    dayList: [],
    agency: null,
    name: "",
    dialog: false,
    url: `/coupon/couponhomeisolationbyagency/all?startDate=${moment(new Date()).format(
      "YYYY-MM-DD"
    )}`,
    dateRange: []
  }),
  computed: {
    tomorow() {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      date.setHours(0);
      return date;
    }
  },
  components: {
    Datatables,
    FilterInput,
    CouponIsolateEditable
  },
  async mounted() {},
  watch: {
    agency() {
      this.url = `/coupon/couponhomeisolationbyagency/${
        this.agencyId ? this.agencyId : "all"
      }?startDate=${this.dateRange[0] ? this.dateRange[0] : ""}&&endDate=${
        this.dateRange[1] ? this.dateRange[1] : ""
      }`;
      // this.$eventBus.$emit("reload-datatable");
    }
  },
  methods: {
    handleAgency(value) {
      this.agencyId = value ? value : "all";
      this.url = `/coupon/couponhomeisolationbyagency/${this.agencyId}?startDate=${
        this.dateRange[0] ? this.dateRange[0] : moment(new Date()).format("YYYY-MM-DD")
      }&&endDate=${this.dateRange[1] ? this.dateRange[1] : ""}`;
    },
    handleDate(val) {
      if (val[0]) {
        this.url = `/coupon/couponhomeisolationbyagency/${
          this.agencyId ? this.agencyId : "all"
        }?startDate=${val[0] ? val[0] : ""}&&endDate=${val[1] ? val[1] : ""}`;
      }

      // this.$eventBus.$emit("reload-datatable");
    }
  }
};
</script>

<style scope>
.date-input {
  display: flex;
}
</style>
