<template>
  <div>{{ typeSting }}</div>
</template>

<script>
export default {
  props: ["status"],
  computed: {
    typeSting() {
      switch (this.status) {
        case 20:
          return "สำเร็จ";
        case 400:
          return "ยกเลิก";
        default:
          return "";
      }
    }
  }
};
</script>

<style></style>
