import axios from "clients/axios";

export const getUserService = async () => {
  try {
    const res = await axios.get("/account");
    const { data } = res;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const loginService = async payload => {
  try {
    const res = await axios.post("/auth/login", payload);
    const { data } = res;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
