export const menu = [
  {
    name: "หน้าหลัก",
    url: "/"
  },
  {
    name: "โครงการทั้งหมด",
    url: "/project",
    children: [
      {
        name: "โครงการบันทึกแบบร่าง",
        url: "/project/draft"
      },
      {
        name: "โครงการยืนยัน",
        url: "/project/confirm"
      },
      {
        name: "โครงการถูกยกเลิก",
        url: "/project/cancel"
      }
    ]
  },
  {
    name: "หน่วยงาน",
    url: "/foundation"
  },
  {
    name: "Report",
    url: "/report",
    children: [
      {
        name: "ซื้อแต้มบริจาค",
        url: "/report-payment"
      },
      // {
      //   name: "ซื้อแต้มบริจาค",
      //   url: "/buy-points"
      // },
      {
        name: "คูปองบริจาค",
        url: "/donate-points"
      },
      {
        name: "รายงานคูปอง home isolation",
        url: "/report-home-isolation"
      }
    ]
  },
  {
    name: "Import คูปอง",
    url: "/cupong",
    children: [
      {
        name: "ประวัติ",
        url: "/cupong/history"
      }
    ]
  },
  {
    name: "Home isolation",
    url: "/isulation",
    children: [
      {
        name: "หน่วยงาน home isolation",
        url: "/isulation/foundation"
      },
      {
        name: "ลงทะเบียน home isolation",
        url: "/regist-amed"
      },
      {
        name: "template คูปองหน่วยงาน",
        url: "/template-coupon"
      },
      {
        name: "คูปองมื้ออาหารหน่วยงาน",
        url: "/template-coupon-view"
      }
    ]
  }
];
