<template>
  <v-main :style="{ background: background }">
    <Drawer v-model="drawer" />
    <Topbar @toggle-drawer="drawer = !drawer" />
    <div class="main-content">
      <slot />
    </div>
    <!-- <v-sheet class="overflow-y-auto"> </v-sheet> -->
  </v-main>
</template>

<script>
import Topbar from "components/topbar.vue";
import Drawer from "components/drawer/index.vue";
export default {
  components: {
    Topbar,
    Drawer
  },
  data: () => ({
    drawer: true
  }),
  computed: {
    background() {
      return this.$route.meta.background || "#E5E5E5";
    }
  }
};
</script>

<style scoped>
.main-content {
  height: calc(100vh - 70px);
  overflow: auto;
  padding: 24px;
}
</style>
