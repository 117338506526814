<template>
  <div>
    <div class="foundation-action-container">
      <v-btn icon @click="dialog = true">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon @click="handleDelete">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </div>
    <foundation-editable
      v-model="dialog"
      :id="id"
      :name="name"
      :admin="admin"
      :isolate="true"
      :isCreate="false"
    />
  </div>
</template>

<script>
import FoundationEditable from "components/dialog/foundation-editable.vue";
import axios from "clients/axios";
export default {
  props: ["id", "name", "admin"],
  components: {
    FoundationEditable
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    handleDelete() {
      this.$dialog({
        // title: "Error",
        showCancel: true,
        desc: "คุณต้องการลบหน่วยงานนี้หรือไม่",
        icon: "mdi-trash-can-outline",
        cancelLabel: "ยกเลิก",
        okLabel: "ลบหน่วยงาน",
        width: 450,
        onOk: async () => {
          await axios.delete(`/agency/deleteagencyisolate/${this.id}`);
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: "คุณได้ลบหน่วยงานเรียบร้อยแล้ว"
          }).then(() => {
            this.$eventBus.$emit("reload-datatable");
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.foundation-action-container {
  display: flex;
  justify-content: space-around;
}
</style>
