<template>
  <div class="expansion-container">
    <div :class="`expansion-header ${open ? 'active' : ''}`" @click="open = !open">
      <div>{{ title }}</div>
      <div><v-icon :class="`expansion-icon ${open ? 'active' : ''}`">mdi-chevron-down</v-icon></div>
    </div>
    <div :class="`expansion-body ${open ? 'active' : ''}`"><slot /></div>
  </div>
</template>

<script>
export default {
  props: ["title"],
  data: () => ({ open: true })
};
</script>

<style scoped>
.expansion-container {
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  margin: 12px 0;
}
.expansion-header {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #333333;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.expansion-header.active {
  border-bottom: 1px solid #c4c4c4;
}
.expansion-body {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  height: 0;
  padding: 0 24px;
  color: #333333;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}
.expansion-body.active {
  height: auto;
  padding: 12px 24px;
}
.expansion-icon {
  transition: all 0.2s ease-in-out;
}
.expansion-icon.active {
  transform: rotate(180deg);
}
</style>
