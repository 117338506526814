<template>
  <div class="display-flex" v-if="this.status === 'reject'" :style="{ color: statusColor }">
    <div class="center">ยกเลิก</div>
    <v-btn icon @click="handleSendCoupon">
      <v-icon>mdi-refresh</v-icon>
    </v-btn>
  </div>
  <div v-else :style="{ color: statusColor }">{{ statusText }}</div>
</template>

<script>
import axios from "clients/axios";
export default {
  props: ["status", "id", "firstName", "lastName"],
  computed: {
    statusText() {
      switch (this.status) {
        case "waiting":
          return "รอยืนยัน";
        case "approve":
          return "ยืนยันเรียบร้อย";
        case "reject":
          return "ยกเลิก";
        case "discharge":
          return "จำหน่าย";
        default:
          return this.status;
      }
    },
    statusColor() {
      switch (this.status) {
        case "waiting":
          return "#E9A016";
        case "approve":
          return "#4E9F5B";
        case "reject":
          return "#D1392B";
        default:
          return "#00A0DA";
      }
    }
  },
  methods: {
    handleSendCoupon() {
      this.$dialog({
        // title: "Error",
        showCancel: true,
        desc:
          "คุณต้องการส่งคูปองให้\n" + `${this.firstName}` + " " + `${this.lastName}` + " หรือไม่",
        icon: "mdi-refresh",
        cancelLabel: "ยกเลิก",
        okLabel: "ส่งคูปอง",
        width: 500,
        onOk: async () => {
          await axios.get(`/coupon/resendcoupon/${this.id}`);
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: "คุณได้ส่งเรียบร้อยแล้ว"
          }).then(() => {
            this.$eventBus.$emit("reload-datatable");
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.display-flex {
  display: flex;
  /*justify-content: space-around;*/
}
.center {
  align-self: center;
}
.card-text-desc {
  padding: 10px important;
}
</style>
