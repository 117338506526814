<template>
  <v-dialog v-model="localDialog">
    <v-card style="border-radius: 6px;">
      <div class="export-container">
        <i-date-range-history
          v-model="dateRange"
          label="ช่วงเวลา"
          hide-details
        ></i-date-range-history>
        <v-btn
          outlined
          color="#1E4D94"
          style="text-transform: none"
          :href="
            `${urlExport}?cuponId=${cuponId}&startDate=${this.startDate}&endDate=${this.endDate}`
          "
          >Export file</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dateRange: [],
    startDate: "",
    endDate: "",
    urlExport: `${process.env.VUE_APP_BASE_URL_REPORT}/exportreportcoupon`
  }),
  props: {
    value: {},
    cuponId: {}
  },
  computed: {
    localDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  watch: {
    async dateRange() {
      if (this.dateRange.length == 2 && this.dateRange[0] > this.dateRange[1]) {
        let tmp = this.dateRange[0];
        this.dateRange[0] = this.dateRange[1];
        this.dateRange[1] = tmp;
      }
      this.startDate = this.dateRange[0] ? this.dateRange[0] : null;
      this.endDate = this.dateRange[1] ? this.dateRange[1] : null;
    }
  }
};
</script>

<style scoped>
.export-container {
  padding: 28px;
}
</style>
