<template>
  <v-dialog v-model="localDialog" class="dialog-isolate">
    <v-card style="border-radius: 6px">
      <v-card-actions class="justify-end">
        <v-btn text @click="localDialog = false">ยกเลิก</v-btn>
      </v-card-actions>
      <div class="foundation-form-container">
        <div class="form-title">{{ titelText }}</div>
        <div class="form-title">วันที่</div>
        <v-form ref="form" lazy-validation>
          <div class="form-container">
            <div class="form-input">
              <DateTime v-model="date"></DateTime>
            </div>
          </div>
          <div>
            <!-- <div>วันที่ : {{ dayFormat }}</div> -->
            <div class="ests-container">
              <div class="agency-cnt">
                <div>
                  <div>
                    <div>หน่วยงาน :</div>
                    <i-select-ss
                      v-model="data.agencyId"
                      label="เลือกหน่วยงาน"
                      url="/agency/isolate"
                      item-text="name"
                    ></i-select-ss>
                  </div>
                  <div>
                    <div>มื้อ :</div>
                    <div>
                      <i-select
                        :items="items"
                        v-model="data.ets"
                        item-value="value"
                        item-text="text"
                        label="ระบุมื้อ"
                      >
                      </i-select>
                    </div>
                  </div>
                  <div>
                    <div>ชื่อคูปอง :</div>
                    <i-select-ss
                      v-model="localsynCoupon"
                      label="เลือกคูปอง"
                      :url="url"
                      item-text="couponName"
                      item-value="couponId"
                      @text-selected="textSelected"
                    ></i-select-ss>
                  </div>
                </div>
                <div>
                  <div>
                    <div>refId :</div>
                    <i-text-field
                      :disabled="isDisabled"
                      v-model="localsynCoupon"
                      :rules="[v => !!v || 'ระบุเลขอ้างอิงคูปอง']"
                      label="เลขอ้างอิงคูปอง"
                    ></i-text-field>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-action">
            <i-button @click="handleClick">{{ btnText }}</i-button>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "clients/axios";
import DateTime from "components/inputs/date";
import moment from "moment";
export default {
  components: { DateTime },
  props: {
    value: {},

    isCreate: {
      default: true
    },
    isolate: {
      default: false
    },
    data: {
      default: () => {}
    }
  },
  data: () => ({
    lacalName: null,
    rules: [v => !!v || "กรุณากรอกชื่อหน่วยงาน"],
    item: {},
    date: moment(new Date()).format("YYYY-MM-DD"),
    localsynCoupon: null
  }),
  watch: {
    name: {
      handler(val) {
        this.lacalName = val;
      },
      immediate: true
    },
    data: {
      handler(val) {
        this.date = val.day;
        this.localsynCoupon = val.refId;
      },
      immediate: true
    },
    date: {
      handler(val) {
        this.data.day = val;
      },
      immediate: true
    },
    localsynCoupon(val) {
      this.data.refId = val;
      console.log("localsynCoupon", val);
      // const res = await axios.get(this.url);
      // const { data } = res.data;
      // this.items = data;
    }
  },
  computed: {
    url() {
      console.log("this.day", this.dateformat);
      return `/coupon/getcouponsynhomeisolate/${this.dateformat}`;
    },
    dateformat() {
      return moment(this.date).format("YYYY-MM-DD");
    },
    isDisabled() {
      return true;
    },
    localDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    btnText() {
      return this.isCreate ? "สร้าง" : "บันทึก";
    },
    titelText() {
      return this.isCreate ? "สร้างคูปอง" : "แก้ไขคูปอง";
    },
    items() {
      if (
        1 == 2 &&
        this.date &&
        moment(this.date).format("DD/MM/YYYY") == moment(new Date()).format("DD/MM/YYYY")
      ) {
        console.log("ddate", moment(new Date()).format("HH"));
        if (parseInt(moment(new Date()).format("HH")) < 9) {
          return [
            {
              value: "lunch",
              text: "มื้อเที่ยง"
            },
            {
              value: "dinner",
              text: "มื้อเย็น"
            }
          ];
        } else if (parseInt(moment(new Date()).format("HH")) < 13) {
          return [
            {
              value: "dinner",
              text: "มื้อเย็น"
            }
          ];
        } else {
          return [];
        }
      }
      return [
        {
          value: "braekfast",
          text: "มื้อเช้า"
        },
        {
          value: "lunch",
          text: "มื้อเที่ยง"
        },
        {
          value: "dinner",
          text: "มื้อเย็น"
        }
      ];
    }
  },
  methods: {
    async handleClick() {
      try {
        if (this.$refs.form.validate()) {
          const { agencyId, id, couponName, day, ets, refId, status } = this.data;
          await axios.post(`/coupon/couponhomeisolation`, {
            id,
            agencyId,
            couponName,
            day,
            ets,
            refId,
            status
          });
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: `คุณได้${this.titelText}เรียบร้อยแล้ว`
          }).then(() => {
            this.date = null;
            this.localDialog = false;
            this.$eventBus.$emit("reload-datatable");
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    dateInput(value) {
      this.data.day = value;
      console.log(this.data);
    },
    textSelected(val) {
      console.log("textddd", val);
      this.data.couponName = val;
    }
  }
};
</script>

<style scoped>
.foundation-form-container {
  padding: 28px;
}
.form-title {
  padding: 12px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #333333;
}
.form-container {
  display: flex;
}
.form-input {
  flex: 3;
}
.form-action {
  flex: 1;
  padding-left: 2px;
}
.form-action-cancle {
  flex: 1;
  padding-left: 2px;
  color: yellow;
}
@media only screen and (max-width: 678px) {
  .form-container {
    flex-direction: column;
  }
}
.agency-cnt {
  display: block;
  width: 100%;
}
.btn-cont {
  display: flex;
}
</style>
