<template>
  <div>
    <i-header title="Template หน่วยงาน"><div></div></i-header>
    <v-form ref="form" v-model="valid.valid">
      <FilterInput
        ref="FilterInput"
        @on-click="handleClick"
        :hasDate="true"
        @selct-data="selctData"
        @selct-meal="selctMeal"
      />
      <!-- <Detail /> -->
      <!-- <Uploadfile
        v-model="file"
        :valid="imageValid"
        class="mt-3 "
      /> -->
      <!-- <ImageUpload
        v-model="file"
        :valid="imageValid"
        class="mt-3 "
        v-show="selectedMeal && selectedAgency"
      /> -->
      <CouponDetail
        ref="CouponDetail"
        id="CouponDetail"
        :mode="mode"
        :coupon_type="coupon_type"
        :prop_detail="prop_detail"
        v-show="selectedMeal && selectedAgency"
      />
      <CouponTime
        ref="CouponTime"
        :coupon_type="coupon_type"
        @openDialogDateTime="openDialogDateTime"
        :prop_time_period="prop_time_period"
        :mode="mode"
        v-show="selectedMeal && selectedAgency"
      />
      <DialogDateTime
        ref="DialogDateTime"
        @dataFormDialogDateTime="dataFormDialogDateTime"
        :mode="mode"
        :prop_time_period="prop_time_period"
        :prop_date_for_mode_edit="prop_date_for_mode_edit"
        v-show="selectedMeal && selectedAgency"
      />
      <CouponSetPermission
        ref="CouponSetPermission"
        :mode="mode"
        :coupon_type="coupon_type"
        :create_type="create_type"
        :prop_permission_coupon="prop_permission_coupon"
        :collectQuota_old="collectQuota_old"
        :quota_old="quota_old"
        :item_coupon_data="item_coupon_data"
        :prop_campaing="prop_campaing"
        v-show="selectedMeal && selectedAgency"
      />
      <CouponCamPaing
        ref="CouponCamPaing"
        id="CouponCamPaing"
        :mode="mode"
        :coupon_type="coupon_type"
        :prop_campaing="prop_campaing"
        :campaign_list="campaign_list"
        v-show="selectedMeal && selectedAgency"
      />
      <!-- <CouponNotificationSms
      ref="CouponNotificationSms"
      :mode="mode"
      :coupon_type="coupon_type"
      :create_type="create_type"
      :prop_member="prop_member"
      :list_member_group="list_member_group"
      :list_member="list_member"
      @openDialogMenberGroup="openDialogMenberGroup"
      @openDialogMenberOnlyUser="openDialogMenberOnlyUser"
    />
    <DialogMenberGroup
      ref="DialogMenberGroup"
      :prop_member="prop_member"
      :list_member_group="list_member_group"
    />
    <DialogMenberOnlyUser
      ref="DialogMenberOnlyUser"
      :prop_member="prop_member"
      :list_member="list_member"
    /> -->
      <!-- <DialogNotificationPicture
      ref="DialogNotificationPicture"
      @setdataCreateUpdateCoupon="setdataCreateUpdateCoupon"
    /> -->
    </v-form>
    <ToolbarTitle
      ref="ToolbarTitle"
      :coupon_type="coupon_type"
      :mode="mode"
      :valid="valid"
      :prop_img="prop_img"
      :prop_detail="prop_detail"
      :prop_time_period="prop_time_period"
      :prop_permission_coupon="prop_permission_coupon"
      @setdataCreateUpdateCoupon="setdataCreateUpdateCoupon"
      @openDialogNotificationPicture="openDialogNotificationPicture"
      @uploadImageProfile="uploadImageProfile"
      @getDataitemAll="getDataitemAll"
      @openDialogCencelCreate="openDialogCencelCreate"
      v-show="selectedMeal && selectedAgency"
    />
    <DialogNotificationPicture
      ref="DialogNotificationPicture"
      @setdataCreateUpdateCoupon="setdataCreateUpdateCoupon"
    />
  </div>
</template>

<script>
import moment from "moment";
import axios from "clients/axios";
// import { columns } from "data/tables/regist-amed-columns";
// import Datatables from "components/datatables/index.vue";
import FilterInput from "./modules/filter-input";
import { mapActions } from "vuex";
// import Detail from "./detail/index.vue";
// import Uploadfile from "./detail/uploadfile.vue";
import CouponDetail from "./detail/couponDetail.vue";
import CouponTime from "./detail/couponTime.vue";
import DialogDateTime from "./detail/DialogDateTime.vue";
import CouponSetPermission from "./detail/couponSetPermission.vue";
import CouponCamPaing from "./detail/couponCamPaing.vue";
import ToolbarTitle from "./detail/ToolbarTitle.vue";
// import ImageUpload from "components/inputs/image-upload";
// import CouponNotificationSms from "./detail/couponNotificationSms.vue";
import DialogNotificationPicture from "./detail/DialogNotificationPicture.vue";
// import DialogMenberGroup from "./detail/DialogMenberGroup.vue";
// import DialogMenberOnlyUser from "./detail/DialogMenberOnlyUser.vue";
export default {
  components: {
    FilterInput,
    // Detail,
    // Uploadfile,
    CouponDetail,
    CouponTime,
    DialogDateTime,
    CouponSetPermission,
    CouponCamPaing,
    ToolbarTitle,
    // ImageUpload,
    DialogNotificationPicture
    // Uploadfile
    // CouponNotificationSms,
    // DialogNotificationPicture,
    // DialogMenberGroup,
    // DialogMenberOnlyUser
  },
  data: () => ({
    // columns,
    loading: false,
    meal_select: "",
    name: "",
    statusSelected: "",
    url: `/syn/homeisolation/getpatient?createTime=${moment(Date.now()).format(
      "YYYY-MM-DD"
    )}&name=&agencyId=`,
    /// date time
    valid: {
      valid: true
    },
    form_data_time: "",
    prop_time_period: {
      start_date_keep: null,
      end_date_keep: null,
      no_exp_keep: false,
      start_date_use: null,
      end_date_use: null,
      no_exp_use: false,

      hour_start_keep: "",
      min_start_keep: "",

      hour_end_keep: "",
      min_end_keep: "",

      hour_start_use: "",
      min_start_use: "",

      hour_end_use: "",
      min_end_use: ""
    },
    mode: {
      mode: "CREATE"
    },
    coupon_type: "",
    prop_date_for_mode_edit: {
      start_keep: null,
      end_keep: null,

      start_use: null,
      end_use: null
    },
    // coupon detail
    prop_detail: {
      name_coupon: "",
      code_coupon: "",
      description_coupon: "",
      status: ""
    },
    // permission limit coupon
    prop_permission_coupon: {
      quota: "",
      collectQuota: "",
      check_box_unlimit: "",
      userCap: "",
      userCap_selected: "",
      userCap_text_field: "",
      discountType: "",
      discountAmount_money: "",
      discountAmount_percent: "",

      discountAmount: "",
      discountMaximum: "",
      spendMinimum: null,
      pointToReward: "",
      type_discount: "",
      discountType_name: "any"
    },
    // campaing
    prop_campaing: {
      departmentStoreId: "",
      campaign: "",
      tags: []
    },
    ///img
    prop_img: {
      item_img: "",
      id_img: null,
      check_img: false
    },
    //file excle
    prop_excle: {
      file_excel: []
    },
    create_type: "",
    collectQuota_old: "",
    quota_old: "",
    item_coupon_data: {},
    campaign_list: [],
    // department_list: [
    //   {
    //     departmentName: "one chat Food",
    //     departmentStoreId: "5fb69c9133836a18f22b5789"
    //   }
    // ],
    //member group
    prop_member: {
      select_member: "",
      sendAll: false,
      sendGroupList: [],
      sendMemberList: []
    },
    list_member_group: [],
    list_member: [],
    copyCoupon: false,
    //image file
    file: null,
    file2: null,
    model: {},
    imageValid: { error: true, message: "กรุณาเลือกรูปภาพ" },
    selectedMeal: null,
    selectedAgency: null,
    couponAgency: {}
  }),

  computed: {
    dateNow() {
      return moment(Date.now()).format("YYYY-MM-DD");
    },
    // body() {
    //   const { id, modelKey, isFormData } = this;
    //   let model = {
    //     [modelKey]: id,
    //     ...this.model
    //   };
    //   if (isFormData) {
    //     delete model.lastUpdateTime;
    //     delete model.createTime;
    //     const formData = new FormData();
    //     Object.keys(model).forEach(k => {
    //       if (model[k]) {
    //         if (k === "startTime" || k === "endTime") {
    //           formData.append(k, moment(model[k]).format("YYYY-MM-DD"));
    //         } else {
    //           formData.append(k, model[k]);
    //         }
    //       }
    //     });
    //     if (this.file) {
    //       formData.append("file", this.file);
    //     }
    //     return formData;
    //   }
    //   return model;
    // },
    isSaveEven() {
      return !!this.id;
    }
  },
  created() {
    this.getAgenciesHomei();
    this.getCampaignList();
    let agencyId = this.$route.params.agencyId;
    if (agencyId) {
      // console.log("check");
      this.selctData(agencyId);
      // this.selctMeal(meal);
    }
  },
  watch: {
    file(val) {
      if (val) {
        this.imageValid.error = false;
        console.log(val);
        // const formData = new FormData();
        // formData.append("couponImageFile", val);
        // console.log(formData);
        // this.file2 = formData;
      }
    }
  },
  methods: {
    ...mapActions("agency", ["getAgenciesHomei"]),
    async handleClick({ name, selected, date }) {
      this.name = name;
      this.statusSelected = selected;
      //   console.log(name, selected, date);
      this.url = `/syn/homeisolation/getpatient?agencyId=${
        selected ? selected : ""
      }&name=${name}&createTime=${date ? date : ""}`;
    },
    openDialogDateTime(type) {
      this.$refs.DialogDateTime.open(type);
    },
    dataFormDialogDateTime(form_data) {
      this.form_data_time = form_data;
      this.$refs.CouponTime.refDataDateTime(form_data);
    },
    openDialogMenberGroup() {
      this.$refs.DialogMenberGroup.open();
    },
    openDialogMenberOnlyUser() {
      this.$refs.DialogMenberOnlyUser.open();
    },
    openDialogNotificationPicture() {
      this.$refs.DialogNotificationPicture.open();
    },
    async getCampaignList() {
      const res = await axios.get("/coupon/tags-campaign");
      this.campaign_list = res.data.data.listCampaign;
      // console.log(this.campaign_list);
      return this.campaign_list;
    },
    uploadImageProfile() {
      // console.log("test");
      setTimeout(() => {
        if (
          this.prop_img.id_img != null &&
          this.mode.mode == "CREATE" &&
          this.prop_img.check_img == true
        ) {
          // console.log("1----->");
          this.setdataCreateUpdateCoupon();
        } else if (
          this.prop_img.id_img != null &&
          this.mode.mode == "CREATE" &&
          this.prop_img.check_img == false
        ) {
          // console.log("12----->");
          this.setdataCreateUpdateCoupon();
        } else if (this.mode.mode == "EDIT" && this.prop_img.check_img == false) {
          this.setdataCreateUpdateCoupon();
        } else if (
          this.prop_img.id_img != null &&
          this.mode.mode == "EDIT" &&
          this.prop_img.check_img == true
        ) {
          this.setdataCreateUpdateCoupon();
        }
      }, 3000);
      this.setdataCreateUpdateCoupon();
    },
    setdataCreateUpdateCoupon() {
      // let coupon_type_send = "";
      // let layer_ref_id = "";
      // let layer_type = "";
      // let user_cap = 0;
      let discount_amount = "";
      let discount_maximum = "";
      //discount_amount
      if (this.prop_permission_coupon.discountType == "money") {
        discount_amount = this.prop_permission_coupon.discountAmount_money;
      } else {
        discount_amount = this.prop_permission_coupon.discountAmount_percent;
      }
      //discountMaximum
      if (this.prop_permission_coupon.type_discount == "limitless") {
        discount_maximum = "";
      } else {
        discount_maximum = this.prop_permission_coupon.discountMaximum;
      }
      //notification
      let send_all = false;
      if (this.prop_member.select_member == "all") {
        send_all = true;
      } else {
        send_all = false;
      }
      //campaign
      let campaign;
      let departmentStoreId;
      if (
        this.prop_campaing.departmentStoreId == "" ||
        this.prop_campaing.departmentStoreId == null
      ) {
        departmentStoreId = "";
        campaign = "";
      } else {
        departmentStoreId = this.prop_campaing.departmentStoreId;
        campaign = this.prop_campaing.campaign;
      }
      // console.log("departmentStoreId++++", departmentStoreId);
      // console.log("campaign++++", campaign);
      // const formData = new FormData();
      // formData.append("file", format_data);
      let format_data = {
        couponDetail: {
          couponName: this.prop_detail.name_coupon,
          couponCode: this.prop_detail.code_coupon,
          couponDescription: this.prop_detail.description_coupon
        },
        couponCenterDetail: {
          campaign: campaign,
          departmentStoreId: departmentStoreId,
          tags: this.prop_campaing.tags,
          discountType: this.prop_permission_coupon.discountType_name
        },
        couponDate: {
          collectStartDate: this.prop_time_period.start_date_keep,
          collectEndDate: this.prop_time_period.end_date_keep,
          useEndDate: this.prop_time_period.end_date_use,
          useStartDate: this.prop_time_period.start_date_use
        },
        couponRule: {
          discountAmount: discount_amount,
          discountMaximum: discount_maximum,
          spendMinimum: parseInt(this.prop_permission_coupon.spendMinimum),
          discountType: this.prop_permission_coupon.discountType,
          quota: parseInt(this.prop_permission_coupon.quota),
          collectQuota: parseInt(this.prop_permission_coupon.collectQuota),
          userCap: 1
        },
        couponSendAndNotification: {
          sendAll: send_all,
          sendGroupList: this.prop_member.sendGroupList,
          sendMemberList: this.prop_member.sendMemberList
        },
        couponImagePath: this.prop_img.id_img,
        status: this.prop_detail.status,
        agencyId: this.selectedAgency,
        meal: this.selectedMeal
        // couponImageFile: this.file
        // pointToReward: this.prop_permission_coupon.pointToReward,
        // couponType: coupon_type_send,
        // layerRefId: layer_ref_id,
        // layerType: layer_type,
        // fileExcel: this.prop_excle.file_excel
      };

      console.log("test", format_data);
      // console.log(this.mode);

      if (this.mode.mode == "CREATE") {
        this.createCoupon(format_data);
      } else if (this.mode.mode == "EDIT") {
        this.updateCoupon(format_data);
      }
    },
    getDataitemAll() {
      this.getitemDataCoupon();
    },
    async getCouponDetail(agencyId) {
      const res = await axios.get(`/coupon/template/${agencyId}`);
      this.couponAgency = res.data.data;
      console.log(res);
      if (this.couponAgency == null) {
        // console.log("nodata");
        this.cleatData();
      } else {
        // console.log("data");
        this.$refs.FilterInput.clear();
      }
      let meal = this.$route.params.meal;
      if (meal) {
        this.selctMeal(meal);
      }
    },
    async createCoupon(format_data) {
      // console.log(format_data);
      // let id = "6140223915fed8577e8bedb5";
      // let meal = "breakfast";
      // const formData = new FormData();
      // formData.append("agencyId", id);
      // formData.append("meal", meal);
      // formData.append("couponImageFile", this.file);
      try {
        // const res = await axios.post(`/coupon/template`, format_data, {
        //   headers: {
        //     "Content-Type": "multipart/form-data"
        //   }
        // });
        const res = await axios.post(`/coupon/template`, format_data);
        const data_res = res.data;
        console.log(data_res);
        if (data_res.message == "create success") {
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: `คุณได้สร้างคูปองเรียบร้อยแล้ว`
          }).then(() => {
            // this.date = null;
            this.localDialog = false;
            location.replace("/template-coupon");
            // this.loading = false;
          });
        } else {
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "warning",
            desc: `ไม่สามารถสร้างคูปองได้`
          }).then(() => {
            // this.date = null;
            this.localDialog = false;
            // this.loading = false;
          });
        }

        // }
      } catch (error) {
        console.log(error);
      }
    },
    async updateCoupon(format_data) {
      try {
        const res = await axios.put(`/coupon/template` + this.item_id, format_data);
        const data_res = res.data;
        console.log(data_res);
        if (data_res.message == "update success") {
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: `แก้ไขข้อมูลคูปองสำเร็จ`
          }).then(() => {
            // this.date = null;
            // this.localDialog = false;
            this.loading = false;
          });
        } else {
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "warning",
            desc: `ไม่สามารถแก้ไขคูปองได้`
          }).then(() => {
            // this.date = null;
            // this.localDialog = false;
            this.loading = false;
          });
        }

        // }
      } catch (error) {
        console.log(error);
      }
    },
    selctData(item) {
      // console.log(item);
      // let agency_id_view = this.$route.params.agencyId;
      // if (agency_id_view) {
      //   item = agency_id_view;
      //   this.getCouponDetail(item);
      //   this.selectedAgency = item;
      // } else {
      //   this.getCouponDetail(item);
      //   this.selectedAgency = item;
      // }
      this.getCouponDetail(item);
      this.selectedAgency = item;
    },
    selctMeal(item) {
      // console.log(item);
      this.selectedMeal = item;
      if (this.couponAgency) {
        if (item == "breakfast") {
          this.setCoupondata(this.couponAgency.breakfast);
        }
        if (item == "lunch") {
          this.setCoupondata(this.couponAgency.lunch);
        }
        if (item == "dinner") {
          this.setCoupondata(this.couponAgency.dinner);
        }
      }
    },
    setCoupondata(item) {
      // console.log(item);
      // console.log("selctMeal", item);
      if (item) {
        //detail ------------------------------------------------------------------
        this.prop_detail.name_coupon = item.couponDetail.couponName;
        this.prop_detail.code_coupon = item.couponDetail.couponCode;
        this.prop_detail.description_coupon = item.couponDetail.couponDescription;
        this.prop_detail.status = item.status;
        //time ------------------------------------------------------------------
        this.prop_time_period.start_date_keep = item.couponDate.collectStartDate;
        this.prop_time_period.end_date_keep = item.couponDate.collectEndDate;
        this.prop_time_period.end_date_use = item.couponDate.useEndDate;
        this.prop_time_period.start_date_use = item.couponDate.useStartDate;

        // couponRule ------------------------------------------------------------------
        //discount_amount
        if (item.couponRule.discountType == "money") {
          this.prop_permission_coupon.discountAmount_money = item.couponRule.discountAmount;
        } else {
          this.prop_permission_coupon.discountAmount_percent = item.couponRule.discountAmount;
        }
        this.prop_permission_coupon.discountAmount_money;
        this.prop_permission_coupon.collectQuota = parseInt(item.couponRule.collectQuota); //
        // this.prop_permission_coupon.discountAmount = discount_amount;
        this.prop_permission_coupon.discountMaximum = item.couponRule.discountMaximum;
        this.prop_permission_coupon.discountType = item.couponRule.discountType; //
        this.prop_permission_coupon.quota = item.couponRule.quota;
        this.prop_permission_coupon.spendMinimum = parseInt(item.couponRule.spendMinimum); //
        // this.prop_permission_coupon.userCap = item.couponRule.userCap;
        this.prop_permission_coupon.discountType_name = item.couponCenterDetail.discountType;
        // this.prop_permission_coupon.userCap_text_field = item.couponRule.userCap;

        // campaign ------------------------------------------------------------------
        this.prop_campaing.departmentStoreId = item.couponCenterDetail.departmentStoreId;
        this.prop_campaing.campaign = item.couponCenterDetail.campaign;
        this.prop_campaing.tags = item.couponCenterDetail.tags;
      } else {
        // console.log("nodata");
        this.cleatData();
      }
    },
    cleatData() {
      // detail
      this.prop_detail.name_coupon = "";
      this.prop_detail.description_coupon = "";
      this.prop_detail.status = "inactive";
      this.prop_detail.code_coupon = "";
      // console.log(this.prop_detail);
      // time
      this.prop_time_period.start_date_keep = null;
      this.prop_time_period.end_date_keep = null;
      this.prop_time_period.end_date_use = null;
      this.prop_time_period.start_date_use = null;
      //rule
      this.prop_permission_coupon.collectQuota = "";
      this.prop_permission_coupon.discountAmount = "";
      this.prop_permission_coupon.discountAmount_money = "";
      this.prop_permission_coupon.discountAmount_percent = "";
      this.prop_permission_coupon.discountMaximum = "";
      this.prop_permission_coupon.discountType = "";
      this.prop_permission_coupon.quota = "";
      this.prop_permission_coupon.spendMinimum = "";
      this.prop_permission_coupon.discountType_name = "any";
      //campaign
      this.prop_campaing.departmentStoreId = "";
      this.prop_campaing.campaign = "";
      this.prop_campaing.tags = [];
      this.prop_detail.code_coupon = "";

      // this.$refs.form.validate();
      this.$refs.form.resetValidation();
      // this.$refs.form.reset();
      // this.valid.valid = false;
    },
    openDialogCencelCreate() {
      let item = this.selectedMeal;
      if (this.couponAgency) {
        if (item == "breakfast") {
          this.setCoupondata(this.couponAgency.breakfast);
        }
        if (item == "lunch") {
          this.setCoupondata(this.couponAgency.lunch);
        }
        if (item == "dinner") {
          this.setCoupondata(this.couponAgency.dinner);
        }
      } else {
        this.cleatData();
      }
    }
  }
};
</script>

<style scoped></style>
