import Home from "views/home";
import Project from "views/project";
import ProjectUpdate from "views/project/update";
import DraftProject from "views/draft-project";
import ConfirmProject from "views/confirm-project";
import CancelProject from "views/cancel-project";
import Foundation from "views/foundation";
import Report from "views/report";
import ReportDetails from "views/report/details";
import ReportPayments from "views/report/payments";
import DonateDetails from "views/report/donate-details";
import DonateCoupon from "views/report/donate-coupon";
import AllocateCoupon from "views/report/allocate-coupon";
import ReportDonatePoints from "views/report/donate-points";
import ReportHomeIsolation from "views/report/report-home-i";
import Cupong from "views/cupong";
import CupongHistory from "views/cupong/history";
import Isulation from "views/isulation";
import IsulationCoupon from "views/isulation/coupon";
import IsulationCouponImport from "views/isulation/couponImport";
import IsulationFoundation from "views/isulation/foundation";
import RegistAmed from "views/regist-amed";
// template coupon
import TemplateCoupon from "views/template-coupon";
import TemplateCouponView from "views/template-coupon-view";
import RegistAmedDetails from "views/regist-amed/detail";
import Login from "views/login";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { background: "#f5f5f5", layout: "basic" }
  },
  {
    path: "/project",
    name: "project",
    component: Project,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/project/update",
    component: ProjectUpdate,
    meta: { background: "#f5f5f5", isUpdate: { value: true, prevPath: "/project" } }
  },
  {
    path: "/project/update/:id",
    component: ProjectUpdate,
    meta: { background: "#f5f5f5", isUpdate: { value: true, prevPath: "/project" } }
  },
  {
    path: "/project/draft",
    name: "draft",
    component: DraftProject,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/project/confirm",
    name: "confirm",
    component: ConfirmProject,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/project/cancel",
    name: "cancel",
    component: CancelProject,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/foundation",
    name: "foundation",
    component: Foundation,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/report",
    name: "report",
    component: Report,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/report/:id",
    name: "reportDetail",
    component: ReportDetails,
    meta: { background: "#f5f5f5", isUpdate: { value: true, prevPath: "/report" } }
  },
  {
    path: "/report-payment",
    name: "reportPayment",
    component: ReportPayments,
    meta: { background: "#f5f5f5", isUpdate: { value: true, prevPath: "/report" } }
  },
  // {
  //   path: "/buy-points",
  //   name: "buyPoints",
  //   component: ReportBuyPoints,
  //   meta: { background: "#f5f5f5", isUpdate: { value: true, prevPath: "/report" } }
  // },
  {
    path: "/donate-points",
    name: "donatePoints",
    component: ReportDonatePoints,
    meta: { background: "#f5f5f5", isUpdate: { value: true, prevPath: "/report" } }
  },
  {
    path: "/report-home-isolation",
    name: "reportHomeIsolation",
    component: ReportHomeIsolation,
    meta: { background: "#f5f5f5", isUpdate: { value: true, prevPath: "/report" } }
  },
  {
    path: "/donate-detail/:id",
    name: "donateDetail",
    component: DonateDetails,
    meta: { background: "#f5f5f5", isUpdate: { value: true, prevPath: "/report/donate-points" } }
  },
  {
    path: "/donate-coupon/:agencyId/:couponId",
    name: "donateCoupon",
    component: DonateCoupon,
    meta: { background: "#f5f5f5", isUpdate: { value: true, prevPath: "/report/donate-points" } }
  },
  {
    path: "/allocate-coupon/:agencyId/:couponId",
    name: "allocateCoupon",
    component: AllocateCoupon,
    meta: { background: "#f5f5f5", isUpdate: { value: true, prevPath: "/report/donate-points" } }
  },
  {
    path: "/cupong",
    name: "coupon",
    component: Cupong,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/cupong/history",
    name: "couponHistory",
    component: CupongHistory,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/isulation",
    name: "isulation",
    component: Isulation,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/isulation/coupon",
    name: "isulationCoupon",
    component: IsulationCoupon,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/isulation/coupon/:id",
    name: "reportDetail",
    component: IsulationCouponImport,
    meta: { background: "#f5f5f5", isUpdate: { value: true, prevPath: "/isulation/coupon" } }
  },
  {
    path: "/isulation/foundation",
    name: "isulation foundation",
    component: IsulationFoundation,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/regist-amed",
    name: "regist-amed",
    component: RegistAmed,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/template-coupon/:agencyId/:meal",
    name: "template-coupon",
    component: TemplateCoupon,
    meta: { background: "#f5f5f5" }
  },
   {
    path: "/template-coupon",
    name: "template-coupon",
    component: TemplateCoupon,
    meta: { background: "#f5f5f5" ,}
  },
  {
    path: "/template-coupon-view",
    name: "template-coupon-view",
    component: TemplateCouponView,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/regist-amed/:id",
    name: "registAmedDetail",
    component: RegistAmedDetails,
    meta: { background: "#f5f5f5", isUpdate: { value: true, prevPath: "/regist-amed" } }
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   component: About,
  //   meta: { layout: "basic-layout" }
  // }
];

export default routes;
