<template>
  <div>
    <i-header title="หน่วยงาน"><div></div></i-header>
    <!-- <br /> -->
    <FilterInput @on-click="handleClick" :selectItems="status" />
    <!-- <br /> -->
    <i-header title="รายชื่อหน่วยงาน">
      <div class="action-container">
        <v-btn
          outlined
          color="#1E4D94"
          style="text-transform: none"
          :href="`${urlExport}?name=${name}&status=${statusSelected}`"
          >Export file</v-btn
        >
      </div>
    </i-header>
    <Datatables
      :search-name="name"
      :columns="columns"
      :selected-status="statusSelected"
      url="/agency/getAgencyReport"
      modelKey="agencyProjectId"
    />
    <foundation-editable v-model="dialog" />
  </div>
</template>

<script>
import FilterInput from "components/filter-input/index.vue";
import Datatables from "components/datatables/index.vue";
import FoundationEditable from "components/dialog/foundation-editable.vue";

import { columns } from "data/tables/report-columns";
import { status } from "data/status-agency";

export default {
  name: "foundation",
  components: {
    FilterInput,
    Datatables,
    FoundationEditable
  },
  data: () => ({
    columns,
    status,
    dialog: false,
    name: "",
    statusSelected: null,
    urlExport: `${process.env.VUE_APP_BASE_URL_API}/agency/exportagencyreport`
  }),
  methods: {
    handleClick({ name, selected }) {
      console.log(selected);
      this.name = name;
      this.statusSelected = selected;
    }
  }
};
</script>

<style scoped>
.action-container {
  display: flex;
}
.action-button {
  margin-right: 5px;
}
</style>
