<template>
  <div :style="{ color: statusColor }">{{ statusText }}</div>
</template>

<script>
export default {
  props: ["status"],
  computed: {
    statusText() {
      switch (this.status) {
        case "waiting":
          return "รอยืนยัน";
        case "approve":
          return "ยืนยันเรียบร้อย";
        case "reject":
          return "ยกเลิก";
        case "discharge":
          return "จำหน่าย";
        default:
          return this.status;
      }
    },
    statusColor() {
      switch (this.status) {
        case "waiting":
          return "#E9A016";
        case "approve":
          return "#4E9F5B";
        case "reject":
          return "#D1392B";
        default:
          return "#00A0DA";
      }
    }
  }
};
</script>

<style></style>
