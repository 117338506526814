export const form = [
  {
    key: "basic",
    name: "กรอกข้อมูลโครงการ",
    showAction: true,
    form: [
      {
        isImageProfile: true,
        title: "รูปโลโก้โครงการ",
        name: "picture",
        rules: [v => !!v || "ระบุชื่อหน่วยงาน"]
      },
      {
        isImage: true,
        title: "รูปภาพโครงการ",
        name: "image"
      },
      {
        isExpansionPanel: true,
        title: "ส่วนที่ 1 : ชื่อหน่วยงาน",
        formItems: [
          {
            type: "select-ss",
            name: "agencyId",
            label: "ชื่อหน่วยงาน",
            inputLabel: "ระบุชื่อหน่วยงาน",
            url: "/agency/agency",
            itemText: "name",
            rules: [v => !!v || "ระบุชื่อหน่วยงาน"]
          }
        ]
      },
      {
        isExpansionPanel: true,
        title: "ส่วนที่ 2 : ข้อมูลโครงการบริจาค",
        formItems: [
          {
            type: "text",
            name: "name",
            label: "ชื่อโครงการรับบริจาค",
            inputLabel: "ระบุชื่อโครงการรับบริจาค",
            rules: [v => !!v || "ระบุชื่อโครงการรับบริจาค"]
          },
          {
            type: "date-range",
            name: "date",
            label: "วันที่เริ่มต้น - สิ้นสุด โครงการ",
            firstInputKey: "startTime",
            secondInputKey: "endTime",
            rules: [v => !!v || "ระบุวันที่เริ่มต้น"]
          },
          {
            type: "checkbox",
            name: "isCouponDonate",
            label: "เปิดบริจาคคูปอง",
            rules: [v => !!v || "ระบุวันที่เริ่มต้น"]
          },
          { type: "desc", name: "details", label: "รายละเอียดโครงการ" }
        ]
      }
    ]
  },
  {
    key: "second",
    name: "เพิ่มผู้ดูแลโครงการ",
    form: []
  },
  {
    key: "third",
    name: "คูปอง",
    form: []
  }
];
