<template>
  <div>
    <Form :tabs="form" get-url="/agency/getdetail/" post-url="/agency/save" />
  </div>
</template>

<script>
import Form from "components/form";
import { form } from "data/forms/project-form";
export default {
  components: {
    Form
  },
  data: () => ({ form })
};
</script>

<style scoped></style>
