import { SET_LIST_ROOM, GET_FAILURE } from "./constant";
import { botChatListRoom, addMember, botChatListRoomByEmail } from "services/member.service";

export default {
  async getListRoom({ commit }) {
    try {
      const result = await botChatListRoom();
      commit(SET_LIST_ROOM, result.list_friend);
    } catch (error) {
      commit(GET_FAILURE, error.message);
    }
  },
  async getListRoomByEmail({ commit }, payload) {
    try {
      const result = await botChatListRoomByEmail(payload);
      return result;
    } catch (error) {
      commit(GET_FAILURE, error.message);
    }
  },
  async addMember({ commit }, payload) {
    try {
      const result = await addMember(payload.body, payload.id);
      return result;
    } catch (error) {
      commit(GET_FAILURE, error.message);
    }
  }
};
