<template>
  <div>
    <i-header title="รายละเอียดแต้ม" />
    <FilterInput @on-click="handleClick" :selectItems="status" />
    <i-header title="รายละเอียดแต้ม"> </i-header>
    <Datatables
      :columns="columns"
      :url="`/donate-coupon/listcouponagencyproject/${this.$route.params.id}`"
      :search-name="name"
      :selected-status="statusSelected"
    />
  </div>
</template>

<script>
import FilterInput from "components/filter-input/index-donation.vue";
import Datatables from "components/datatables/index.vue";
import { columns } from "data/tables/report-detail-donate-columns";
import { status } from "data/status-donation";
export default {
  name: "project",
  components: {
    Datatables,
    FilterInput
  },
  data: () => ({
    columns,
    status,
    name: null,
    statusSelected: null
    // typeItems: [
    //   {
    //     key: "all",
    //     text: "เปิดใช้งาน-ปิดการใช้งาน"
    //   },
    //   {
    //     key: "active",
    //     text: "เปิดใช้งาน"
    //   },
    //   {
    //     key: "inactive",
    //     text: "ปิดการใช้งาน"
    //   }
    // ]
    // statusSelected: "all"
    // urlExport: `${process.env.VUE_APP_BASE_URL_API}/donate/exportdonatehistory`
  }),
  methods: {
    handleClick({ name, selected }) {
      console.log(selected);
      this.name = name;
      this.statusSelected = selected;
      console.log(this.statusSelected);
    }
  }
};
</script>

<style scoped>
.filter-container {
  display: flex;
  margin: 12px 0;
}
.filter-type {
  flex: 2;
  padding-right: 10px;
}
.filter-date {
  flex: 2;
  padding-left: 10px;
}
.filter-action {
  flex: 1;
  display: flex;
  margin-left: 10px;
  align-items: flex-end;
}
</style>
