<template>
  <i-card>
    <div class="filter-input-container">
      <!-- <div class="filter-date">
        <div class="filter-title">วันที่</div>
        <div>
          <i-date v-model="date" label="วันที่" hide-details></i-date>
        </div>
        <div class="filter-action">
          <i-button @click="handleClick">
            ค้นหา
          </i-button>
        </div>
      </div> -->
      <div class="filter-date">
        <div class="filter-title">วันที่</div>
        <i-date v-model="date" label="วันที่" hide-details></i-date>
      </div>
      <div class="filter-action">
        <i-button @click="handleClick">
          ค้นหา
        </i-button>
      </div>
    </div>
  </i-card>
</template>

<script>
import moment from "moment";
export default {
  props: {
    hasDate: {
      default: () => false
    }
  },
  data: () => ({
    selected: null,
    name: "",
    dateRange: [moment(Date.now()).format("YYYY-MM-DD")],
    date: moment(Date.now()).format("YYYY-MM-DD")
  }),
  computed: {},
  watch: {},
  methods: {
    handleClick() {
      const { date } = this;
      this.$emit("on-click", { date });
      // console.log(date);
    }
  }
};
</script>

<style scoped>
.filter-input-container {
  display: flex;
  /* flex-wrap: wrap; */
}

.status-input {
  flex: 4;
  padding: 8px;
}
.filter-date {
  flex: 6;
  padding: 8px;
}

.filter-action {
  flex: 1;
  padding: 8px;
  display: flex;
  align-items: center;
}
.filter-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;

  margin-bottom: 6px;
}

@media only screen and (max-width: 678px) {
  .filter-input-container {
    flex-direction: column;
  }
}
</style>
