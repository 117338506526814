<template>
  <div :style="x_tablet ? 'background-color: #1E4D94;' : ''">
    <v-app id="inspire">
      <v-content>
        <div v-if="!x_tablet" :style="x_tablet ? '' : 'display:flex; height: 100%;'">
          <div style="background-color: #1E4D94; height: 100%; flex:28">
            <img
              style="margin-left: 17%;
                    margin-top: 12%;"
              width="40%"
              src="@/assets/logo_dn.png"
            />
          </div>
          <div style="background: rgba(30, 77, 148, 0.6); height: 100%; flex:1"></div>
          <div style="background: rgba(30, 77, 148, 0.2);; height: 100%; flex:1"></div>
          <div style="background: white; height: 100%; flex:5"></div>
        </div>

        <!-- <img :src="data_img" /> -->
        <v-container
          fluid
          fill-height
          :style="x_tablet ? '' : 'position: absolute; top: 0; left:28% '"
        >
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
              <v-card class="card-login" width="100%">
                <v-card-title class="txt-title py-10">
                  <div color="primary">เข้าสู่ระบบ</div>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                  <v-form style="width:85%">
                    <v-text-field
                      v-model="username"
                      name="login"
                      type="text"
                      placeholder="ชื่อผู้ใช้งาน"
                      flat
                      hide-details
                      solo
                      class="txt-field mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      id="password"
                      name="password"
                      placeholder="Password"
                      solo
                      flat
                      hide-details
                      class="txt-field"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                    ></v-text-field>

                    <v-checkbox v-model="checkbox">
                      <template v-slot:label>
                        <div
                          style="font-family: Prompt;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 22px; color: #333333;"
                        >
                          ยอมรับข้อกำหนดการใช้บริการ ONE ID และนโยบายความคุ้มครองข้อมูลส่วนบุคคล
                        </div>
                      </template>
                    </v-checkbox>
                  </v-form>
                </v-card-text>
                <v-card-actions class="txt-title">
                  <v-btn :disabled="!checkbox" color="primary" @click="clickLogin"
                    >เข้าสู่ระบบ</v-btn
                  >
                </v-card-actions>
                <v-card-subtitle class="text-center pb-10">
                  <span @click="RePassword" style="cursor: pointer;">ลืมรหัสผ่าน ?</span>
                </v-card-subtitle>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-content>
    </v-app>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      show1: false,
      username: null,
      password: null,
      checkbox: null
    };
  },
  computed: {
    mobile() {
      if (this.$vuetify.breakpoint.width < 375) {
        return true;
      } else {
        return false;
      }
    },
    x_tablet() {
      if (this.$vuetify.breakpoint.width < 475) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions("auth", ["login"]),
    async clickLogin() {
      const data = await this.login({ username: this.username, password: this.password });

      if (data.result == "Success") {
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("account_id", data.account_id);
        localStorage.setItem("username", data.username);
        localStorage.setItem("refresh_token", data.refresh_token);

        this.$router.push("/");
      } else {
        this.$dialog({
          title: "Error",
          showCancel: false,
          type: "error",
          desc: `login ไม่สำเร็จ username หรือ password ไม่ถูกต้อง`
        });
      }
    },
    RePassword() {
      window.open("https://one.th/type_forgot_password", "_blank");
    }
  }
};
</script>

<style>
.v-application {
  background: transparent !important;
}
.txt-title {
  justify-content: center;
  color: #1e4d94;
  font-family: Prompt;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 32px !important;
  line-height: 48px !important;
}
.txt-field {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
  font-family: Prompt;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.card-login {
  background: #fafafa !important;
  box-shadow: 0px 4px 16px rgba(117, 117, 117, 0.25) !important;
  border-radius: 48px !important;
}
</style>
