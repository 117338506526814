export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "คำนำหน้าชื่อ", value: "accountTitleTh" },
  { text: "ชื่อ", value: "firstNameTh" },
  { text: "สกุล", value: "lastNameTh" },
  { text: "เบอร์โทร", value: "mobileNo" },
  { text: "email", value: "email" },
  { text: "หน่วยงาน", value: "agencyId", componentName: "custom-agency-fullname" },
  { text: "วันที่เริ่มต้น-วันที่สิ้นสุด", value: "start", componentName: "date-custom-homeiregis" },
  { text: "มื้อแรก", value: "firstMeal", componentName: "custom-firstmeal-fullname" },
  { text: "มื้อสิ้นสุด", value: "lastMeal", componentName: "custom-lastmeal-fullname" },
  { text: "สถานะ", value: "status", componentName: "status-custom" },
  { text: "remark", value: "remark" },
  { text: "", value: "action", componentName: "actiom-regist-amed" }
];
