export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "คำนำหน้าชื่อ", value: "title" },
  { text: "ชื่อ", value: "firstName" },
  { text: "สกุล", value: "lastName" },
  { text: "รหัสนักศึกษา", value: "studentId" },
  { text: "เบอร์โทร", value: "phone" },
  { text: "อีเมล", value: "email" },
  { text: "ผู้รับผิดชอบ", value: "owner" },
  { text: "สถานะ", value: "status", componentName: "status-custom-2" },
  { text: "หมายเหตุ", value: "remark" },
  { text: "", value: "action", componentName: "import-isolate-action" }
];
