import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import vuetify from "plugins/vuetify";
import DialogPlugin from "plugins/dialog";
import Button from "components/button.vue";
import Card from "components/card/index.vue";
import TextField from "components/inputs/text-field.vue";
import TextArea from "components/inputs/text-area.vue";
import Select from "components/inputs/select.vue";
import SelectSS from "components/inputs/select-ss.vue";
import ListItem from "components/drawer/list-item.vue";
import Header from "components/header";
import ActionHeader from "components/header/action";

import SweetAlertIcons from "vue-sweetalert-icons";
import VueLoading from "vuejs-loading-plugin";
import DateRange from "components/inputs/date-range.vue";
import Date from "components/inputs/date.vue";
import DateRangeHistory from "components/inputs/date-range-history.vue";
import VueGtag from "vue-gtag";

import "layouts/index";
import "components/datatables/index";
// v-mask
import VueMask from "v-mask";
Vue.config.productionTip = false;

Vue.component("i-button", Button);
Vue.component("i-card", Card);
Vue.component("i-text-field", TextField);
Vue.component("i-text-area", TextArea);
Vue.component("i-select", Select);
Vue.component("i-select-ss", SelectSS);
Vue.component("i-list-item", ListItem);
Vue.component("i-header", Header);
Vue.component("i-action-header", ActionHeader);
Vue.component("i-date-range", DateRange);
Vue.component("i-date-range-history", DateRangeHistory);
Vue.component("i-date", Date);

Vue.use(DialogPlugin);

Vue.use(SweetAlertIcons);

Vue.use(VueLoading, {
  text: "loading" // default 'Loading'
});

Vue.use(VueGtag, {
  config: { id: "G-D4CL5M0QCJ" }
});
Vue.use(VueMask);

Vue.prototype.$eventBus = new Vue();

export const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
