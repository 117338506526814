<template>
  <div>
    <i-header title="ลงทะเบียน AMED"><div></div></i-header>
    <FilterInput
      @on-click="handleClick"
      nameLabel="ค้นหาชื่อ-สกุล"
      nameInputLabel="ค้นหาชื่อ-สกุล"
      :hasDate="true"
    />

    <Datatables :columns="columns" :url="url" />
  </div>
</template>

<script>
import moment from "moment";
import { columns } from "data/tables/regist-amed-columns";
import Datatables from "components/datatables/index.vue";
import FilterInput from "./modules/filter-input";
import { mapActions } from "vuex";
export default {
  data: () => ({
    columns,
    name: "",
    statusSelected: "",
    url: `/syn/homeisolation/getpatient?createTime=${moment(Date.now()).format(
      "YYYY-MM-DD"
    )}&name=&agencyId=`
  }),
  components: { Datatables, FilterInput },
  computed: {
    dateNow() {
      return moment(Date.now()).format("YYYY-MM-DD");
    }
  },
  created() {
    this.getAgenciesHomei();
  },
  watch: {},
  methods: {
    ...mapActions("agency", ["getAgenciesHomei"]),
    async handleClick({ name, selected, date }) {
      this.name = name;
      this.statusSelected = selected;
      //   console.log(name, selected, date);
      this.url = `/syn/homeisolation/getpatient?agencyId=${
        selected ? selected : ""
      }&name=${name}&createTime=${date ? date : ""}`;
    }
  }
};
</script>

<style scoped></style>
