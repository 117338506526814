<template>
  <div class="vertical-align">
    <v-btn text color="#1E4D94" @click="handleClickDonate"
      ><v-icon style="margin-right: 5px">mdi-eye</v-icon>บริจาค
    </v-btn>
    <v-btn text color="#1E4D94" @click="handleClickAllocate"
      ><v-icon style="margin-right: 5px">mdi-eye</v-icon>แจก
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["agencyProjectId", "couponId"],
  methods: {
    handleClickDonate() {
      this.$router.push(`/donate-coupon/${this.agencyProjectId}/${this.couponId}`);
    },
    handleClickAllocate() {
      this.$router.push(`/allocate-coupon/${this.agencyProjectId}/${this.couponId}`);
    }
  }
};
</script>

<style scoped>
.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200%;
  white-space: nowrap;
}
.vertical-align {
  display: flex;
}
</style>
