<template>
  <div>{{ etsText }}</div>
</template>

<script>
export default {
  props: ["meal"],
  computed: {
    etsText() {
      switch (this.meal) {
        case "breakfast":
          return "มื้อเช้า";
        case "lunch":
          return "มื้อเที่ยง";
        case "dinner":
          return "มื้อเย็น";
        default:
          return "";
      }
    }
  }
};
</script>

<style></style>
