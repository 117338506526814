<template>
  <div>
    {{ etsText }}
  </div>
</template>

<script>
export default {
  props: ["templateData"],
  computed: {
    etsText() {
      switch (this.templateData.status) {
        case "inactive":
          return "ไม่เปิดการใช้งาน";
        case "active":
          return "เปิดการใช้งาน";
        default:
          return "";
      }
    }
  }
};
</script>

<style></style>
