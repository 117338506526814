export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "ชื่อหน่วยงาน", value: "agencyName" },
  { text: "ชื่อโครงการ", value: "name" },
  { text: "จำนวนผู้ดูแล", value: "agencyMemberNumber" },
  { text: "แต้มสะสม", value: "total" },
  { text: "แต้มคงเหลือ", value: "amount" },
  { text: "สถานะ", value: "status", componentName: "status-custom" },
  { text: "", value: "", componentName: "see-detail-report" }
];
