import axios from "clients/axios";

export const getAgenciesService = async () => {
  try {
    const res = await axios.get("agency");
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getAgenciesServiceHomei = async () => {
  try {
    const res = await axios.get("agency/isolate");
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getAgencyService = async id => {
  try {
    const res = await axios.get(`agency/getdetail/${id}`);
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getAgencyAmountService = async () => {
  try {
    const res = await axios.get(`/agency/getProjectAmount`);
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
