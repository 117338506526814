<template>
  <v-navigation-drawer
    v-model="localValue"
    app
    clipped
    floating
    width="180px"
    class="navigation-drawer"
  >
    <v-list dense nav>
      <v-list-item v-if="isUpdatePage" @click="handlePrev" link>
        <v-list-item-icon>
          <v-icon>mdi-chevron-left</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>ย้อนกลับ</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <i-list-item v-else v-for="(item, i) in menu" v-bind="item" :key="i" />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { menu } from "data/menu";
export default {
  props: ["value"],
  data: () => ({ menu }),
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    isUpdatePage() {
      return this.$route.meta.isUpdate && this.$route.meta.isUpdate.value;
    }
  },
  methods: {
    handlePrev() {
      this.$router.push(this.$route.meta.isUpdate.prevPath);
    }
  }
};
</script>

<style></style>
