import axios from "clients/axios";

export const botChatListRoom = async () => {
  try {
    const res = await axios.get("onechat/getlistroom");
    const { data } = res;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const addMember = async (payload, id) => {
  try {
    const res = await axios.post(`agency/addmember/${id}`, payload);
    const { data } = res;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const botChatListRoomByEmail = async payload => {
  try {
    const res = await axios.get(`onechat/getlistroom/${payload}`);
    const { data } = res;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
