<template>
  <div>
    <i-header
      title="โครงการบันทึกแบบร่าง"
      action-title="สร้างโครงการใหม่"
      @action-click="$router.push('/project/update')"
    />
    <!-- <br /> -->
    <FilterInput :filterStatus="false" @on-click="handleClick" />
    <!-- <br /> -->
    <i-header title="รายชื่อโครงการล่าสุด">
      <!-- <v-btn outlined color="#1E4D94" style="text-transform: none">Export file</v-btn> -->
    </i-header>
    <Datatables
      :search-name="name"
      :columns="columns"
      url="/agency/getProjectByStatus"
      :isPostUrl="true"
      :bodyData="{ status: 'waiting' }"
    />
  </div>
</template>

<script>
import FilterInput from "components/filter-input/index.vue";
import Datatables from "components/datatables/index.vue";
import { columns } from "data/tables/project-columns";

export default {
  name: "draft",
  components: {
    FilterInput,
    Datatables
  },
  data: () => ({ columns, name: null }),
  methods: {
    handleClick({ name }) {
      this.name = name;
    }
  }
};
</script>

<style scoped></style>
