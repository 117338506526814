export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "ชื่อคูปอง", value: "couponName" },
  { text: "orderId", value: "orderId" },
  { text: "ส่วนลด", value: "discount" },
  { text: "ใช้ส่วนลด", value: "useDiscount" },
  { text: "เวลาใช้งาน", value: "useTime", componentName: "date-custom" }
];
