<template>
  <div>
    <div class="foundation-action-container">
      <v-btn icon @click="deleteDialog">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import axios from "clients/axios";
export default {
  props: ["id", "name"],
  components: {},
  data: () => ({
    dialog: false
  }),
  methods: {
    deleteDialog() {
      console.log("oo", this.id);
      this.$dialog({
        // title: "Error",
        showCancel: true,
        desc: "คุณต้องการลบผู้ดูแลท่านนี้หรือไม่",
        icon: "mdi-trash-can-outline",
        cancelLabel: "ยกเลิก",
        okLabel: "ยืนยัน",
        onOk: async () => {
          await axios.delete(`/agency/deleteagencymember/${this.id}`);
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: "คุณได้ลบผู้ดูแลเรียบร้อยแล้ว"
          }).then(() => {
            this.$eventBus.$emit("reload-datatable");
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.foundation-action-container {
  display: flex;
  justify-content: space-around;
}
</style>
