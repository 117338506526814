<template>
  <div>
    <i-header title="โอนแต้มบริจาค"><div></div></i-header>

    <FilterInput @on-click="handleClick" :filterStatus="false" :hasDate="false" />

    <i-header title="รายละเอียดโอนแต้มบริจาค">
      <div class="action-container">
        <!-- <v-btn outlined color="#1E4D94" style="text-transform: none" :href="`${urlExport}`"
          >Export file</v-btn
        > -->
      </div>
    </i-header>
    <Datatables :columns="columns" :url="url" modelKey="agencyProjectId" :search-name="name" />
  </div>
  <!-- :href="`${urlExport}?agencyName=${name}&startDate=${this.startDate}&endDate=${this.endDate}`" -->
</template>

<script>
import { columns } from "data/tables/report-donate-points-columns";
import Datatables from "components/datatables/index.vue";
import FilterInput from "components/filter-input/index.vue";

export default {
  data: () => ({
    columns,
    name: null,
    url: `/donate-coupon/listagencyproject-report`
  }),
  components: { Datatables, FilterInput },
  methods: {
    async handleClick({ name }) {
      this.name = name;
      this.url = await `/donate-coupon/listagencyproject-report`;
    }
  }
};
</script>

<style scoped></style>
