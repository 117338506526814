<template>
  <div class="status-container">
    <table class="table-status" style="width:100%">
      <tr>
        <td><v-icon color="yellow">mdi-information-outline</v-icon>{{ couponImport.waiting }}</td>
        <td><v-icon color="green">mdi-check-circle</v-icon>{{ couponImport.approve }}</td>
        <td><v-icon color="red">mdi-close-circle</v-icon>{{ couponImport.reject }}</td>
      </tr>
    </table>
  </div>
</template>

<script scope>
export default {
  props: ["couponImport"]
};
</script>

<style>
.status-container {
  display: flex;
}
.status-container div {
  display: flex;
}
</style>
