<template>
  <div>
    <!-- สร้างคูปอง -->
    <v-toolbar class="px-1" flat color="grey lighten-4" v-if="mode.mode == 'CREATE'">
      <v-btn class="text-lowercase px-6" outlined color="primary" @click="cancelCreateCoupon()">
        คืนค่าข้อมูล
      </v-btn>
      <v-btn
        class="text-lowercase ml-3 px-6"
        color="primary"
        @click="createCoupon()"
        :disabled="!valid.valid"
      >
        บันทึกข้อมูล
      </v-btn>
    </v-toolbar>
    <!-- ดูข้อมูลคูปอง -->
    <v-toolbar class="px-1" flat color="grey lighten-4" v-else-if="mode.mode == 'NORMAL'">
      <!-- <v-toolbar-title>
        <h5>ข้อมูลคูปอง{{ headerTitle() }}</h5>
      </v-toolbar-title>
      <v-spacer></v-spacer> -->
      <v-btn
        class="ml-5 mr-2 text-lowercase"
        icon
        small
        color="black"
        @click="editCoupon()"
        v-if="prop_detail.status != 12"
      >
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>

      <v-btn class="mx-2 text-lowercase" icon small color="black" @click="deleteCoupon()">
        <v-icon> mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-toolbar>
    <!-- แก้ไขข้อมูลคูปอง -->
    <v-toolbar class="px-1" flat color="grey lighten-4" v-if="mode.mode == 'EDIT'">
      <!-- <v-toolbar-title>
        <h5>แก้ไขข้อมูลคูปอง{{ headerTitle() }}</h5>
      </v-toolbar-title>
      <v-spacer></v-spacer> -->
      <v-btn class="text-lowercase px-8" outlined color="primary" @click="cancelModeUpdate()">
        คืนค่าข้อมูล
      </v-btn>
      <v-btn
        class="text-lowercase ml-3 px-8"
        color="primary"
        @click="updateCoupon()"
        :disabled="!valid.valid"
      >
        บันทึกข้อมูล
      </v-btn>
    </v-toolbar>
  </div>
</template>
<script>
export default {
  props: {
    coupon_type: {
      type: String,
      require: true,
      default: ""
    },
    mode: {
      type: Object,
      require: true,
      default() {}
    },
    prop_img: {
      type: Object,
      require: true,
      default() {}
    },
    prop_detail: {
      type: Object,
      require: true,
      default() {}
    },
    prop_time_period: {
      type: Object,
      require: true,
      default() {}
    },
    prop_permission_coupon: {
      type: Object,
      require: true,
      default() {}
    },
    valid: {
      type: Object,
      require: true,
      default() {}
    }
  },

  data() {
    return {
      list_menu: [
        {
          index: 0,
          title: "รายงาน"
        },
        {
          index: 1,
          title: "ปิดการใช้งานบัญชีนี"
        }
      ],
      menu: ""
      // create_type: ""
    };
  },
  watch: {},
  computed: {},
  created() {
    // this.create_type = this.$route.params.create_type;
  },
  methods: {
    headerTitle() {
      switch (this.coupon_type) {
        // case "DISCOUNT COUPONS":
        //   return "ส่วนลด";
        //   break;
        // case "WELCOME COUPONS":
        //   return "ต้อนรับ";
        //   break;
        // case "BIRTHDAY COUPONS":
        //   return "วันเกิด";
        //   break;
        // case "MEMBER COUPONS":
        //   return "สมาชิก";
        //   break;
        case "SPECIAL COUPONS":
          return "พิเศษ";
        case "SPECIALEXCEL COUPONS":
          return "พิเศษหลายใบ";
        case "ATK COUPONS":
          return "ATK";
        default:
          return "";
      }
    },
    //insert------------->
    createCoupon() {
      console.log(this.prop_img.check_img);
      if (this.prop_img.check_img == false) {
        console.log("ไม่มีรูป");
        // this.$emit("openDialogNotificationPicture");
        this.$emit("setdataCreateUpdateCoupon");
      } else {
        console.log("มีรูป");
        this.$emit("uploadImageProfile");
      }
      // if (this.coupon_type == "SPECIALEXCEL COUPONS") {
      //   if (this.prop_img.check_img == false) {
      //     console.log("ไม่มีรูป");
      //     this.$emit("openDialogNotificationPicture");
      //   } else {
      //     console.log("มีรูป");
      //     this.$emit("uploadImageProfile");
      //   }
      // } else {
      //   // ปกติ
      //   if (this.prop_detail.code_coupon != "" && this.prop_detail.code_coupon.length < 4) {
      //     this.$swal({
      //       title: "ข้อมูลโค้ดส่วนลดไม่ถูกต้อง",
      //       text: " กรุณาระบุโค้ดส่วนลด 4- 6 ตัวอักษร",
      //       width: 600,
      //       icon: "warning",
      //       confirmButtonColor: "#3085d6",
      //       customClass: "swal-wide",
      //       confirmButtonText: `ตกลง`
      //     });
      //   } else {
      //     if (
      //       parseInt(this.prop_permission_coupon.quota) <
      //       parseInt(this.prop_permission_coupon.userCap_text_field)
      //     ) {
      //       this.$swal({
      //         title: "ไม่สามารถสร้างคูปองได้",
      //         text: "เนื่องจากจำนวนคูปอง น้อยกว่าจำนวนสิทธิ์",
      //         width: 600,
      //         icon: "warning",
      //         confirmButtonColor: "#3085d6",
      //         customClass: "swal-wide",
      //         confirmButtonText: `ตกลง`
      //       });
      //     } else {
      //       console.log("elerrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", this.prop_img);
      //       if (this.copyCoupon == false) {
      //         console.log("ปกติ");
      //         if (this.prop_img.check_img == false) {
      //           this.$emit("openDialogNotificationPicture");
      //         } else {
      //           this.$emit("uploadImageProfile");
      //         }
      //       } else {
      //         console.log("คัดลอก");
      //         if (this.prop_img.check_img == false && this.prop_img.item_img == null) {
      //           this.$emit("openDialogNotificationPicture");
      //         } else if (this.prop_img.check_img == false && this.prop_img.item_img != null) {
      //           this.$emit("uploadImageProfile");
      //         } else {
      //           this.$emit("uploadImageProfile");
      //         }
      //       }
      //     }
      //   }
      // }
    },
    cancelCreateCoupon() {
      // this.prop_img.check_img = false;
      // this.$session.remove("id_img_coupon");
      // this.$session.remove("status_img_coupon");
      // console.log("222");

      this.$emit("openDialogCencelCreate");
    },
    //update--------------->
    updateCoupon() {
      if (this.prop_detail.code_coupon != "") {
        if (this.prop_detail.code_coupon.length < 4) {
          this.$swal({
            title: "ข้อมูลโค้ดส่วนลดไม่ถูกต้อง",
            text: " กรุณาระบุโค้ดส่วนลด 4- 6 ตัวอักษร",
            width: 600,
            icon: "warning",
            confirmButtonColor: "#3085d6",
            customClass: "swal-wide",
            confirmButtonText: `ตกลง`
          });
        } else {
          this.$emit("openDialogLoading");
          this.$emit("uploadImageProfile");
        }
      } else {
        this.$emit("openDialogLoading");
        this.$emit("uploadImageProfile");
      }
    },
    cancelModeUpdate() {
      this.prop_img.check_img = false;
      this.$emit("openDialogCencelCreate");
    },

    //normal ----------->
    editCoupon() {
      this.mode.mode = "EDIT";
      // this.$emit('getDataitemAll')
    },
    deleteCoupon() {
      this.$emit("openDialogDeleteCoupon");
    }
  }
};
</script>
