<template>
  <div>
    <i-header title="คูปองที่แจก" />
    <FilterInput @on-click="handleClick" :filterStatus="false" />
    <i-header title="รายละเอียดคูปองที่แจก">
      <div class="action-container">
        <v-btn
          outlined
          color="#1E4D94"
          style="text-transform: none"
          :href="
            `${urlExport}?agencyProjectId=${this.$route.params.agencyId}&couponId=${this.$route.params.couponId}`
          "
          >Export file</v-btn
        >
      </div>
    </i-header>
    <Datatables
      :columns="columns"
      :url="
        `/donate-coupon/couponallocate-report?agencyProjectId=${this.$route.params.agencyId}&couponId=${this.$route.params.couponId}`
      "
      :search-name="name"
    />
  </div>
</template>

<script>
import Datatables from "components/datatables/index.vue";
import { columns } from "data/tables/report-allocate-coupon-columns";
import FilterInput from "components/filter-input/index.vue";

export default {
  name: "project",
  components: {
    Datatables,
    FilterInput
  },
  data: () => ({
    columns,
    name: null,
    urlExport: `${process.env.VUE_APP_BASE_URL_API}/donate-coupon/export-couponallocate`
  }),
  methods: {
    handleClick({ name }) {
      this.name = name;
    }
  }
};
</script>

<style scoped>
.filter-container {
  display: flex;
  margin: 12px 0;
}
.filter-type {
  flex: 2;
  padding-right: 10px;
}
.filter-date {
  flex: 2;
  padding-left: 10px;
}
.filter-action {
  flex: 1;
  display: flex;
  margin-left: 10px;
  align-items: flex-end;
}
</style>
