<template>
  <div>{{ donarText }}</div>
</template>

<script>
export default {
  props: ["donorName", "showDonorName"],
  computed: {
    donarText() {
      switch (this.showDonorName) {
        case false:
          return this.donorName;
        case true:
          return "ไม่ประสงค์ลงนาม";
        default:
          return "ไม่ประสงค์ลงนาม";
      }
    }
  }
};
</script>

<style></style>
