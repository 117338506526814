<template>
  <v-dialog v-model="localDialog" max-width="768px">
    <v-card style="border-radius: 6px">
      <div class="foundation-form-container">
        <div class="form-title">{{ titelText }}</div>
        <div class="form-title">ชื่อหน่วยงาน HI</div>
        <v-form ref="form" lazy-validation>
          <div class="form-container">
            <div class="form-input">
              <i-text-field v-model="lacalName" :rules="rules" label="ระบุชื่อหน่วยงาน" />
            </div>
            <div class="form-action">
              <i-button @click="handleClick">{{ btnText }}</i-button>
            </div>
          </div>
        </v-form>
        <div v-show="isolate">
          <div>
            <v-data-table :headers="headers" :items="temp" sort-by="" class="elevation-1">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>ADMIN</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                        เพิ่มผู้ดูแลโครงการ
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedItem.oneMail"
                                label="oneMail"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedItem.remark"
                                label="remark"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close"> ยกเลิก </v-btn>
                        <v-btn color="blue darken-1" text @click="save"> บันทึก </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">คุณต้องการลบผู้ดูแลโครงการหรือไม่?</span>
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">ยกเลิก</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">ตกลง</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                <v-icon small @click="deleteItem(item)"> mdi-trash-can-outline </v-icon>
              </template>
              <template v-slot:no-data>
                <div>{{ nodata }}</div>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "clients/axios";
import { mapActions } from "vuex";
export default {
  props: {
    value: {},
    id: {},
    name: {},
    isCreate: {
      default: true
    },
    isolate: {
      default: false
    },
    admin: {
      default: () => []
    }
  },
  data: () => ({
    lacalName: null,
    rules: [v => !!v || "กรุณากรอกชื่อหน่วยงาน"],
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "oneMail", value: "oneMail" },
      { text: "remark", value: "remark" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      oneMail: "",
      remark: ""
    },
    defaultItem: {
      oneMail: "",
      remark: ""
    },
    temp: [],
    nodata: "ไม่พบข้อมูล"
  }),
  watch: {
    name: {
      handler(val) {
        this.lacalName = val;
      },
      immediate: true
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    localDialog() {
      if (this.admin == null) {
        this.temp = [];
      } else {
        this.temp = this.admin;
      }
    }
  },
  computed: {
    localDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    btnText() {
      return this.isCreate ? "สร้างหน่วยงาน" : "บันทึก";
    },
    titelText() {
      return this.isCreate ? "สร้างหน่วยงาน" : "แก้ไขหน่วยงาน";
    },
    formTitle() {
      return this.editedIndex === -1 ? "เพิ่มผู้ดูแลโครงการ" : "แก้ไขผู้ดูแลโครงการ";
    }
  },

  methods: {
    async handleClick() {
      try {
        if (this.$refs.form.validate()) {
          const { lacalName, id, temp } = this;
          const type = this.isolate ? "isolate" : "agency";
          await axios.post(`/agency/${type}/save`, { id, name: lacalName, admin: temp });
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: `คุณได้${this.titelText}เรียบร้อยแล้ว`
          }).then(() => {
            this.localDialog = false;
            this.$eventBus.$emit("reload-datatable");
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    editItem(item) {
      this.editedIndex = this.temp.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.temp.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.admin.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    ...mapActions("member", ["getListRoom", "addMember", "getListRoomByEmail"]),
    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.temp[this.editedIndex], this.editedItem);
      } else {
        const user = await this.getListRoomByEmail(this.editedItem.oneMail);
        if (user == false) {
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "error",
            desc: "ไม่พบ email ที่กรอก"
          });
        } else {
          let one_Id = { oneId: user.one_id };
          this.editedItem = Object.assign(this.editedItem, one_Id);
          this.temp.push(this.editedItem);
        }
      }
      this.close();
    }
  }
};
</script>

<style scoped>
.foundation-form-container {
  padding: 28px;
}
.form-title {
  padding: 12px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #333333;
}
.form-container {
  display: flex;
}
.form-input {
  flex: 3;
}
.form-action {
  flex: 1;
  padding-left: 10px;
}

.text-h5 {
  text-align: center;
  margin: auto;
  padding: auto !important;
}
@media only screen and (max-width: 678px) {
  .form-container {
    flex-direction: column;
  }
}
</style>
