<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <i-action-header
        @on-edit="isViewing = !isViewing"
        @on-delete="handleDelete"
        :hide-edit="!isViewing || this.model.status === 'reject'"
        :status="statusHandle"
        :color="statusColor"
        :title="titleHandle"
        :canEdit="isSaveEven"
      ></i-action-header>
      <Tabs
        v-model="tab"
        :tabs="tabs"
        :disabed-keys="disabledKeys"
        :isCouponDonate="model.isCouponDonate"
      />
      <br />
      <i-card v-for="t in tabs" :key="t.key" v-show="tab === t.key">
        <div v-if="t.key == 'basic'">
          <template v-for="(form, i) in t.form">
            <div v-if="form.isImageProfile" :key="i">
              <div class="form-title">
                {{ form.title }}
              </div>
              <ImageUploadProfile
                v-model="file2"
                :disabled="isViewing"
                :image-preview="model[form.name]"
                :valid="secondImageValid"
              />
            </div>
            <div v-else-if="form.isImage" :key="i">
              <div class="form-title">
                {{ form.title }}
              </div>
              <ImageUpload
                v-model="file"
                :disabled="isViewing"
                :image-preview="model[form.name]"
                :valid="imageValid"
              />
            </div>

            <expansion-panel v-else :key="i" :title="form.title">
              <FormItem
                v-for="(item, it) in form.formItems"
                :key="it"
                :item="item"
                :model="model"
                v-model="model[item.name]"
                :is-viewing="isViewing"
                @first-input="val => setFirstInput(item.firstInputKey, val)"
                @second-input="val => setFirstInput(item.secondInputKey, val)"
              />
            </expansion-panel>
          </template>
          <div class="form-action" v-if="t.showAction && !isViewing">
            <v-btn
              v-if="id"
              outlined
              color="#1E4D94"
              style="text-transform: none; margin-right: 10px"
              @click="isViewing = true"
              >ยกเลิก</v-btn
            >
            <v-btn
              outlined
              color="#1E4D94"
              style="text-transform: none; margin-right: 10px"
              @click="handleSubmit()"
              v-if="!id || model.status == 'waiting'"
              >บันทึกแบบร่าง</v-btn
            >
            <div>
              <i-button @click="handleSubmit(true)">บันทึก</i-button>
            </div>
          </div>
        </div>
        <div v-else-if="t.key == 'second'">
          <AddMember v-if="tab == 'second'" :id="id" />
        </div>
        <div v-else-if="t.key == 'third'">
          <CouponProject v-if="tab == 'third'" :id="id" />
        </div>
      </i-card>
    </v-form>
  </div>
</template>

<script>
import axios from "clients/axios";
import moment from "moment";
import Tabs from "components/tabs";
import ExpansionPanel from "components/expansion-panel";
import FormItem from "components/form/form-item";
import ImageUpload from "components/inputs/image-upload";
import ImageUploadProfile from "components/inputs/image-profile-upload";
import AddMember from "views/project/add-member";
import CouponProject from "views/project/coupon-project";

const baseUrl = process.env.VUE_APP_BASE_URL_API;

export default {
  props: {
    tabs: {
      default: () => []
    },
    disableSave: {
      default: false
    },
    modelKey: {
      default: () => "id"
    },
    postUrl: {},
    getUrl: {},
    isFormData: {
      default: () => true
    },
    fileKey: {
      default: () => "image"
    }
  },
  components: {
    Tabs,
    ExpansionPanel,
    FormItem,
    ImageUpload,
    ImageUploadProfile,
    AddMember,
    CouponProject
  },
  data: () => ({
    valid: true,
    tab: null,
    // forms: [],
    model: {},
    id: "",
    loading: false,
    isViewing: true,
    isFileChange: false,
    file: null,
    file2: null,
    imageValid: { error: true, message: "กรุณาเลือกรูปภาพ" },
    secondImageValid: { error: true, message: "กรุณาเลือกรูปภาพ" }
  }),
  watch: {
    "$route.params.id": {
      handler(val) {
        this.id = val;
        if (!val) {
          this.isViewing = false;
        }
      },
      immediate: true
    },
    file(val) {
      if (val) {
        this.imageValid.error = false;
      }
    },
    file2(val) {
      if (val) {
        this.secondImageValid.error = false;
      }
    }
  },
  mounted() {
    this.getFormData();
  },
  computed: {
    statusHandle() {
      switch (this.model.status) {
        case "waiting":
          return "รอยืนยัน";
        case "approve":
          return "ยืนยัน";
        case "reject":
          return "ยกเลิก";
        default:
          return "";
      }
    },
    statusColor() {
      switch (this.model.status) {
        case "waiting":
          return "#E9A016";
        case "approve":
          return "#4E9F5B";
        case "reject":
          return "#D1392B";
        default:
          return "#00A0DA";
      }
    },
    titleHandle() {
      return this.id ? "ข้อมูลโครงการ" : "สร้างโครการ";
    },
    disabledKeys() {
      if (this.model.status === "approve") {
        if (this.model.isCouponDonate) {
          return [];
        } else {
          return ["third"];
        }
      }
      return ["second", "third"];
    },
    body() {
      const { id, modelKey, isFormData } = this;
      // const { id, modelKey, isFormData, fileKey } = this;
      let model = {
        [modelKey]: id,
        ...this.model
      };
      if (isFormData) {
        delete model.lastUpdateTime;
        delete model.createTime;
        const formData = new FormData();
        Object.keys(model).forEach(k => {
          if (model[k]) {
            if (k === "startTime" || k === "endTime") {
              formData.append(k, moment(model[k]).format("YYYY-MM-DD"));
            } else {
              formData.append(k, model[k]);
            }
          }
        });
        if (this.file) {
          formData.append("file", this.file);
        }
        if (this.file2) {
          formData.append("fileProfile", this.file2);
        }
        return formData;
      }
      return model;
    },
    isSaveEven() {
      return !!this.id;
    }
  },
  methods: {
    async getFormData() {
      const { getUrl } = this;
      try {
        const res = await axios.get(`${getUrl}${this.id}`);
        this.isFileChange = false;
        this.loading = false;
        const { data } = res.data;
        this.model = data || {
          startTime: "",
          endTime: ""
        };
        // this.rules = {}
      } catch (error) {
        console.log(error);
      }
    },
    submit() {
      if (!this.confirmSubmit) {
        this.handleSubmit();
      }
    },
    async handleSubmit(withStatus = false) {
      try {
        if (this.$refs.form.validate() && !this.secondImageValid.error && !this.imageValid.error) {
          const { postUrl, modelKey } = this;
          const res = await axios.post(postUrl, this.body);
          const {
            data: { [modelKey]: resId }
          } = res.data;
          if (!this.id) {
            window.history.pushState({}, "", `${window.location.href}/${resId}`);
          }
          this.id = resId;
          // this.rules = {};
          if (withStatus) {
            await axios.post(`${baseUrl}/agency/changeProjectStatus/${this.id}`, {
              status: "approve"
            });
          }
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: "อัพเดทสำเร็จ"
          });
          this.getFormData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    setFirstInput(key, val) {
      this.model[key] = val;
    },
    handleDelete() {
      this.$dialog({
        // title: "Error",
        showCancel: true,
        desc: "คุณต้องการลบโครงการนี้หรือไม่",
        icon: "mdi-trash-can-outline",
        cancelLabel: "ยกเลิก",
        okLabel: "ยืนยัน",
        onOk: async () => {
          axios
            .post(`/agency/changeProjectStatus/${this.id}`, { status: "reject" })
            .then(() => {
              this.$dialog({
                // title: "Error",
                showCancel: false,
                type: "success",
                desc: "คุณได้ลบโครงการเรียบร้อยแล้ว"
              }).then(() => {
                this.$router.push("/project");
              });
            })
            .catch(error => {
              throw error;
            });
        }
      });
    }
  }
};
</script>

<style scoped>
.form-action {
  display: flex;
  justify-content: flex-end;
  padding: 18px 0;
}
.form-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
  color: #333333;
}
</style>
