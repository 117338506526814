<template>
  <div>
    <div v-if="!isViewing">
      <div class="date-container">
        <div>
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="localStartDate"
                :label="startDateLabel"
                outlined
                dense
                persistent-hint
                apend-icon="mdi-calendar"
                class="i-text-field"
                v-bind="attrs"
                v-on="on"
                :rules="rules"
                autocomplete="off"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDateMenu"
              no-title
              @input="menu1 = false"
              :max="sDateMax"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="minus">-</div>
        <div>
          <v-menu
            ref="menu1"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="localEndDate"
                :label="endDateLabel"
                outlined
                dense
                persistent-hint
                apend-icon="mdi-calendar"
                class="i-text-field"
                v-bind="attrs"
                v-on="on"
                :disabled="checkbox"
                :rules="roleEndDate"
                autocomplete="off"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDateMenu"
              no-title
              @input="menu2 = false"
              :min="eDateMin"
            ></v-date-picker>
          </v-menu>
        </div>
      </div>
      <div>
        <v-checkbox
          class="i-checkbox"
          v-model="checkbox"
          label="ไม่ระบุวันสิ้นสุด"
          style="margin-top: 0"
        ></v-checkbox>
      </div>
    </div>
    <div v-else class="text-view">
      {{ datePreview }}
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    label: {},
    value: {},
    hideDetails: {
      default: () => false
    },
    required: {
      default: () => false
    },
    rules: {},
    type: {
      default: () => "text"
    },
    disabled: {
      default: () => false
    },
    isViewing: {},
    startDateLabel: {
      default: () => "เริ่มต้น"
    },
    endDateLabel: {
      default: () => "สิ้นสุด"
    },
    startDate: {},
    endDate: {}
  },
  data: () => ({
    startDateMenu: null,
    endDateMenu: null,
    menu1: false,
    menu2: false,
    checkbox: false
  }),
  watch: {
    startDateMenu(val) {
      if (val) {
        this.localStartDate = val;
      }
    },
    endDateMenu(val) {
      if (val) {
        this.localEndDate = val;
      }
    },
    checkbox(val) {
      if (val) {
        this.endDateMenu = null;
        this.localEndDate = null;
      }
    },
    endDate(val) {
      if (!val) {
        this.checkbox = true;
      }
    }
  },
  computed: {
    localStartDate: {
      get() {
        return this.startDate ? moment(this.startDate).format("DD/MM/YYYY") : "";
      },
      set(value) {
        this.$emit("input-start-date", value);
      }
    },
    localEndDate: {
      get() {
        return this.endDate ? moment(this.endDate).format("DD/MM/YYYY") : "";
      },
      set(value) {
        this.$emit("input-end-date", value);
      }
    },
    datePreview() {
      return this.localStartDate + `${this.localEndDate ? ` - ${this.localEndDate}` : ""}`;
    },
    sDateMax() {
      return this.endDate;
    },
    eDateMin() {
      return this.startDate;
    },
    roleEndDate() {
      return [
        v => {
          console.log(!!v);
          if (!this.checkbox && !v) {
            console.log(!!v);
            return "กรุณาระบุวันสิ้นสุดโครงการ";
          }
          return true;
        }
      ];
    }
  }
};
</script>

<style scoped>
.date-container {
  display: flex;
}
.minus {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 20px 20px;
}
</style>

<style>
.i-checkbox.v-input--checkbox .v-input__slot .v-icon {
  color: #757575;
}
.i-checkbox.v-input--checkbox .v-input__slot .v-label {
  color: #757575;
}
</style>
