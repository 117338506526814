<template>
  <div>
    <i-header title="หน่วยงาน"><div></div></i-header>
    <!-- <br /> -->
    <FilterInput
      :filterStatus="false"
      @on-click="handleClick"
      name-label="ชื่อหน่วยงาน"
      name-input-label="ค้นหารายชื่อหน่วยงาน"
    />
    <!-- <br /> -->
    <i-header title="รายชื่อหน่วยงาน">
      <div class="action-container">
        <div class="action-button">
          <i-button @click="dialog = true">เพิ่มหน่วยงาน</i-button>
        </div>
        <!-- <v-btn outlined color="#1E4D94" style="text-transform: none">Export file</v-btn> -->
      </div>
    </i-header>
    <Datatables :search-name="name" :columns="columns" url="/agency/isolate" />
    <foundation-editable v-model="dialog" :isolate="true" />
  </div>
</template>

<script>
import FilterInput from "components/filter-input/index.vue";
import Datatables from "components/datatables/index.vue";
import FoundationEditable from "components/dialog/foundation-editable.vue";

import { columns } from "data/tables/foundation-isolation-columns";

export default {
  name: "foundation",
  components: {
    FilterInput,
    Datatables,
    FoundationEditable
  },
  data: () => ({ columns, dialog: false, name: null }),
  methods: {
    handleClick({ name }) {
      this.name = name;
    }
  }
};
</script>

<style scoped>
.action-container {
  display: flex;
}
.action-button {
  margin-right: 5px;
}
</style>
