<template>
  <div>
    <v-btn icon @click="handleDetail">
      <v-icon>mdi-eye</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  props: ["id"],
  methods: {
    handleDetail() {
      this.$router.push(`/regist-amed/${this.id}`);
    }
  }
};
</script>
<style scoped></style>
