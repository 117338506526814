<template>
  <div>
    <v-select
      v-if="!isViewing"
      class="i-text-field"
      v-model="localValue"
      :label="label"
      outlined
      dense
      style="border-radius: 6px"
      :hide-details="hideDetails"
      :required="required"
      :rules="rules"
      :disabled="disabled"
      :items="items"
      :item-value="itemValue"
      :item-text="itemText"
      :item-color="itemColor"
      :item-disabled="itemDisabled"
      :multiple="multiple"
    ></v-select>
    <div v-else class="text-view">
      {{ preview }}
    </div>
  </div>
</template>

<script>
import axios from "clients/axios";
export default {
  props: {
    label: {},
    value: {},
    hideDetails: {
      default: () => false
    },
    required: {
      default: () => false
    },
    rules: {},
    type: {
      default: () => "text"
    },
    disabled: {
      default: () => false
    },
    itemValue: {
      default: () => "id"
    },
    itemText: {
      default: () => "text"
    },
    itemDisabled: {},
    itemColor: {},
    multiple: {},
    url: {},
    isViewing: {}
  },
  data: () => ({
    items: [],
    text: ""
  }),
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      async set(value) {
        this.$emit("input", value);
        await this.textSeleted(value);
      }
    },
    preview() {
      const item = this.items.find(f => f[this.itemValue] === this.localValue) || {};
      return item[this.itemText];
    }
  },
  mounted() {
    this.getItems();
  },
  watch: {
    url: {
      handler(val) {
        console.log("val", val);
        this.getItems();
      }
    }
  },
  methods: {
    async getItems() {
      try {
        const res = await axios.get(this.url);
        const { data } = res.data;
        this.items = data;
      } catch (error) {
        console.log(error);
      }
    },
    async textSeleted(id) {
      if (this.items) {
        await this.items.forEach(item => {
          if (item.couponId == id) {
            console.log("OK", item.couponName);
            this.text = item.couponName;
            this.$emit("text-selected", this.text);
          }
        });
      }
    }
  }
};
</script>
