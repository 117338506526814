<template>
  <div class="tabs-container">
    <div class="tabs-header">
      <div
        v-for="t in tabs"
        :key="t.key"
        :class="
          `tabs-header-item ${tab === t.key ? 'active' : ''} ${isDisabled(t.key) ? 'disabled' : ''}`
        "
        @click="
          () => {
            if (!isDisabled(t.key)) {
              tab = t.key;
            }
          }
        "
      >
        {{ t.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      default: () => []
    },
    value: {},
    disabedKeys: {
      default: () => []
    },
    isCouponDonate: {
      default: false
    }
  },
  data: () => ({
    tab: null
  }),
  watch: {
    tabs: {
      handler(val) {
        if (val.length > 0) {
          this.tab = val[0].key;
        }
      },
      immediate: true
    },
    value: {
      handler(val) {
        if (val) {
          this.tab = val;
        }
      },
      immediate: true
    },
    tab: {
      handler(val) {
        this.$emit("input", val);
      },
      immediate: true
    }
  },
  methods: {
    isDisabled(key) {
      return this.disabedKeys.includes(key);
    }
  }
};
</script>

<style scoped>
.tabs-header {
  display: flex;
  border-radius: 6px;
  background: #ffffff;
  overflow: hidden;
}
.tabs-header-item {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  cursor: pointer;
  transition: all 0.3 ease-in-out;
  color: #757575;
  padding: 16px;
}
.tabs-header-item.active {
  background: #ccddf8;
  color: #1e4d94;
}
.tabs-header-item:not(:last-child):after {
  position: absolute;
  width: 0;
  height: 0;
  right: -60px;
  border-top: 30px solid transparent;
  border-left: 60px solid transparent;
  border-bottom: 30px solid transparent;
  top: 0;
  content: "";
  z-index: 2;
}
.tabs-header-item:not(:first-child):before {
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  border-top: 30px solid transparent;
  border-left: 60px solid #fff;
  border-bottom: 30px solid transparent;
  top: 0;
  content: "";
  z-index: 1;
}
.tabs-header-item.active:not(:last-child):after {
  border-left: 60px solid #ccddf8;
}
.tabs-header-item.disabled {
  cursor: no-drop;
}
</style>
