<template>
  <div class="tabel-container">
    <Message v-show="messageBok" v-model="sendmessage" :userList="userList"></Message>
    <v-checkbox
      v-show="sendmessage"
      class="i-checkbox"
      v-model="selectAll"
      label="เลือกทั้งหมด"
      style="margin-top: 0"
    ></v-checkbox>
    <v-data-table
      v-model="selected"
      :headers="columns"
      :items="data"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :page.sync="page"
      :show-select="showSelect"
      :header-props="{
        'sort-icon': 'mdi-chevron-up'
      }"
      hide-default-footer
    >
      <template v-slot:no-data>
        <h4>ไม่มีข้อมูล</h4>
      </template>
      <template v-slot:no-results>
        <h4>ไม่พบข้อมูลที่ค้นหา</h4>
      </template>
      <template v-slot:[`item.no`]="{ item: itemValue }">
        <div>
          {{
            (page - 1) * options.itemsPerPage +
              data.findIndex(item => item[modelKey] === itemValue[modelKey]) +
              1
          }}
        </div>
      </template>
      <template v-for="(slot, i) in editColumns" v-slot:[`item.${slot.value}`]="{ item, header }">
        <component :key="i" v-bind:is="slot.componentName" v-bind="{ ...item, header }"></component>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        <div v-if="item.price != null">{{ item.price }}</div>
        <div v-else>0</div>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader
          ref="skeleton"
          :boilerplate="boilerplate"
          :type="type"
          :tile="tile"
          style="width: 100%"
        ></v-skeleton-loader>
      </template>
      <template v-slot:footer>
        <slot name="footer"></slot>
      </template>
      <template v-slot:[`item.check`]="{ item }">
        <div v-if="sendmessage">
          <v-checkbox
            v-model="item.select"
            class="i-checkbox"
            style="margin-top: 0"
            @click="select(item)"
          ></v-checkbox>
        </div>
      </template>
    </v-data-table>

    <!-- <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination> -->
  </div>
</template>

<script>
import axios from "clients/axios";
import Message from "./message/index.vue";
export default {
  name: "index",
  props: {
    columns: {},
    title: {},
    modelName: {},
    createRoute: {},
    url: {},
    isPostUrl: {
      default: false
    },
    bodyData: {},
    modelKey: {
      default: "id"
    },
    disableCreate: {
      default: false
    },
    childKey: {},
    disableDelete: {
      default: false
    },
    searchName: {
      default: ""
    },
    selectedStatus: {},
    dataFromProp: {
      default: () => []
    },
    notGetData: {
      default: false
    },
    messageBok: {
      default: false
    }
  },
  inject: ["theme"],
  data() {
    return {
      total: 10,
      data: [],
      loading: false,
      options: {
        itemsPerPage: 10
      },
      page: 1,
      pageCount: 1,
      dialog: false,
      deleteId: [],
      responsive: false,
      search: {},
      dataColumnsString: "",
      boilerplate: false,
      tile: false,
      type: "table-tbody",
      firstMount: true,
      selected: [],
      showSelect: false,
      timeout: null,
      // queryString: '',
      realData: [],
      sendmessage: false,
      userList: [],
      selectAll: false
    };
  },
  watch: {
    selectAll: {
      handler(val) {
        if (val) {
          this.userList = [];
          this.data.forEach(item => {
            item.select = true;
            this.userList.push(item.userId);
          });
        } else {
          this.userList = [];
          this.data.forEach(item => {
            item.select = false;
          });
        }
      }
    },
    dataFromProp: {
      handler(val) {
        if (val) {
          this.data = [...val];
          this.realData = [...val];
        }
      },
      immediate: true
    },
    sendmessage(val) {
      console.log(val);
      if (val) {
        this.columns.unshift({ text: "เลือก", value: "check" });
      } else {
        this.columns = this.columns.filter(item => {
          return item.value != "check";
        });
      }
    },
    searchName(val) {
      this.data = [...this.realData];
      if (val) {
        this.data = [...this.realData].filter(
          f =>
            (f.name && f.name.toLowerCase().includes(val.toLowerCase())) ||
            (f.agencyName && f.agencyName.toLowerCase().includes(val.toLowerCase())) ||
            (f.couponName && f.couponName.toLowerCase().includes(this.searchName.toLowerCase())) ||
            (f.agencyProjectName &&
              f.agencyProjectName.toLowerCase().includes(this.searchName.toLowerCase()))
        );
      }
      if (this.selectedStatus) {
        this.data = this.data.filter(f => f.status == this.selectedStatus);
      }
    },
    selectedStatus(val) {
      this.data = [...this.realData];
      console.log(this.data);
      console.log(val);
      if (val) {
        this.data = [...this.realData].filter(
          f =>
            f.status == val &&
            ((f.name && f.name.toLowerCase().includes(this.searchName.toLowerCase())) ||
              (f.agencyName &&
                f.agencyName.toLowerCase().includes(this.searchName.toLowerCase())) ||
              (f.couponName &&
                f.couponName.toLowerCase().includes(this.searchName.toLowerCase())) ||
              (f.agencyProjectName &&
                f.agencyProjectName.toLowerCase().includes(this.searchName.toLowerCase())))
        );
      }
      console.log(this.data);
    },
    url() {
      this.getDataFromApi();
    }
  },
  mounted() {
    this.getDataFromApi();
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
    this.$eventBus.$on("reload-datatable", () => {
      this.getDataFromApi();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
    this.$eventBus.$off("reload-datatable", () => {
      this.getDataFromApi();
    });
  },
  computed: {
    editColumns() {
      return this.columns.filter(f => f.componentName);
    }
  },
  components: { Message },
  methods: {
    getDataFromApi() {
      if (this.notGetData) {
        console.log(this.dataFromProp);
        this.data = [...this.dataFromProp];
        this.realData = [...this.dataFromProp];
      } else {
        const { url } = this;
        // const { itemsPerPage } = options;
        //   const start = (page - 1) * itemsPerPage;
        this.data = [];
        this.loading = true;
        if (this.isPostUrl) {
          axios
            .post(url, this.bodyData)
            .then(res => {
              const { data } = res.data;
              this.data = data;
              this.realData = data;
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          axios
            .get(url)
            .then(res => {
              // const { data, recordsFiltered, showSelect } = res.data;
              const { data } = res.data;
              this.data = data;
              this.realData = data;
              // this.editColumns = editColumns;
              // this.total = recordsFiltered;
              // this.showSelect = showSelect;
              // this.pageCount = this.mathCeil(recordsFiltered / itemsPerPage);
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
    getKey(item) {
      if (item[this.modelKey] && typeof item[this.modelKey] === "string") {
        return item[this.modelKey].replace("/", "-");
      }
      return item.id;
    },
    mathCeil(number) {
      return Math.ceil(number);
    },
    onResponsiveInverted() {
      if (window.innerWidth < 600) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
    select(val) {
      if (!this.userList.includes(val.userId)) {
        this.userList.push(val.userId);
      } else {
        this.userList = this.userList.filter(item => {
          return item != val.userId;
        });
      }
    }
  }
};
</script>

<style lang="scss">
.tabel-container {
  /* padding: 10px; */
  .v-data-table {
    border-radius: 0;

    .v-data-footer {
      border-top: none !important;
    }
  }
  .v-data-table table thead th {
    border-bottom: none !important;
  }
  .theme--light.v-data-table
    .v-data-table__wrapper
    table
    tbody
    tr:not(:last-child)
    td:not(.v-data-table__mobile-row) {
    border-bottom: 1px solid #f5f5f5 !important;
  }
  .v-data-table__wrapper {
    table {
      /* border-collapse: collapse; */

      thead {
        background-color: #fafafa !important;

        tr {
          th {
            color: #757575 !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 14px !important;
            line-height: 22px;
            position: relative;

            &:not(:first-child):before {
              position: absolute;
              width: 1px;
              height: 50%;
              content: "";
              background: #eeeeee;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }

            /* .v-icon {
              color: #ffffff !important;
            } */
          }
        }
      }

      tr {
        .v-input__control {
          height: 50px;
        }

        .v-input {
          padding-top: 5px;
          height: 50px;
        }
      }

      tbody {
        .v-data-table__empty-wrapper {
          td {
            padding: 0;
          }
        }

        /* tr:nth-child(odd) {
          background-color: #f5f5f5 !important;
        } */
      }
    }
  }
}
</style>
