<template>
  <v-list-group
    v-if="children"
    @click="$router.push(url).catch(() => {})"
    :value="groupActive"
    active-class="active-item"
    :to="url"
  >
    <template v-slot:activator>
      <v-list-item-title>{{ name }}</v-list-item-title>
    </template>
    <i-list-item v-for="(item, i) in children" :key="i" v-bind="item" :sub-list="true" />
  </v-list-group>
  <v-list-item :active-class="subList ? 'active-sub-list' : 'active-item'" link :to="url" v-else>
    <!-- <v-list-item-icon>
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon> -->

    <v-list-item-content>
      <v-list-item-title>{{ name }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
// import ListItem from "components/drawer/list-item.vue";
export default {
  props: ["children", "url", "name", "icon", "subList"],
  components: {
    // ListItem
  },
  computed: {
    groupActive() {
      return this.children.some(c => c.url === this.$router.currentRoute.fullPath);
    }
  }
};
</script>

<style>
.active-item {
  color: #fff !important;
  background: #1e4d94;
}
.active-sub-list {
  color: #1e4d94 !important;
  background: transparent;
}
</style>
