<template>
  <div>
    <!-- <i-header
      title="จัดการคูปอง"
      action-title="เพิ่มคูปอง"
      @action-click="$router.push('/project/update')"
      
    /> -->
    <i-header title="จัดการคูปอง">
      <div class="action-container">
        <div class="action-button">
          <i-button @click="dialog = true">
            <v-icon color="white">mdi-plus</v-icon>
            เพิ่มคูปอง</i-button
          >
        </div>
      </div>
    </i-header>

    <!-- <Datatables :columns="columns" url="/agency" /> -->
    <Datatables :columns="columns" :url="`/donate-coupon/listcouponagencyproject/${id}`" />
    <AddcCouponProject v-model="dialog" :agencyId="id" />
  </div>
</template>

<script>
import Datatables from "components/datatables/index.vue";
import { columns } from "data/tables/coupon-project-columns";
// import { status } from "data/status-agency";
import AddcCouponProject from "components/dialog/add-coupon-project.vue";

export default {
  name: "project",
  components: {
    Datatables,
    AddcCouponProject
  },
  props: ["id"],
  data: () => ({ columns, status, name: null, statusSelected: null, dialog: false }),
  computed: {
    // ...mapState("member", ["listRoom"])
  },
  methods: {
    handleClick({ name, selected }) {
      console.log(selected);
      this.name = name;
      this.statusSelected = selected;
    }
  }
};
</script>

<style scoped></style>
