<template>
  <i-card>
    <div class="filter-input-container">
      <div class="project-input">
        <div class="filter-title">{{ nameLabel }}</div>
        <div>
          <i-text-field
            v-model="name"
            hide-details
            :label="nameInputLabel || nameLabel"
          ></i-text-field>
        </div>
      </div>
      <div class="status-input">
        <div class="filter-title">หน่วยงาน</div>
        <div>
          <i-select-ss
            hide-details
            v-model="selected"
            label="เลือกหน่วยงาน"
            url="/agency/isolate"
            item-text="name"
          ></i-select-ss>
        </div>
      </div>
      <div class="filter-date" v-if="hasDate">
        <div class="filter-title">วันที่</div>
        <i-date v-model="date" label="วันที่" hide-details></i-date>
      </div>
      <div class="filter-action">
        <i-button @click="handleClick">
          ค้นหา
        </i-button>
      </div>
    </div>
  </i-card>
</template>

<script>
import moment from "moment";
export default {
  props: {
    nameLabel: {
      default: () => "ค้นหารายชื่อโครงการ"
    },
    nameInputLabel: {
      default: () => "ค้นหารายชื่อโครงการ หรือ หน่วยงาน"
    },
    hasDate: {
      default: () => false
    }
  },
  data: () => ({
    date: moment(Date.now()).format("YYYY-MM-DD"),
    selected: null,
    name: "",
    dateRange: [moment(Date.now()).format("YYYY-MM-DD")]
  }),
  methods: {
    handleClick() {
      const { name, selected, date } = this;
      this.$emit("on-click", { name, selected, date });
    }
  }
};
</script>

<style scoped>
.filter-input-container {
  display: flex;
  /* flex-wrap: wrap; */
}
.project-input {
  flex: 5;
  padding: 8px;
}

.status-input {
  flex: 2;
  padding: 8px;
}
.filter-date {
  flex: 2;
  padding: 8px;
}

.filter-action {
  flex: 2;
  padding: 8px;
  display: flex;
  align-items: center;
}

.filter-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;

  margin-bottom: 6px;
}

@media only screen and (max-width: 678px) {
  .filter-input-container {
    flex-direction: column;
  }
}
</style>
