<template>
  <i-header :title="title">
    <div class="action-container">
      <v-btn outlined :color="color" style="text-transform: none; margin-right: 20px" v-if="canEdit"
        >สถานะ: {{ status }}</v-btn
      >
      <v-btn v-if="!hideEdit && this.status != 'reject'" icon @click="$emit('on-edit')">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon @click="$emit('on-delete')" v-if="canEdit && this.status != 'ยกเลิก'">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </div>
  </i-header>
</template>

<script>
export default {
  props: {
    title: {
      default: () => "รายชื่อหน่วยงาน"
    },
    status: {
      default: () => "ยืนยันแล้ว"
    },
    color: {
      default: () => "#4E9F5B"
    },
    hideEdit: {
      default: () => false
    },
    canEdit: {
      default: () => true
    }
  }
};
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.header-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}
.action-container {
  display: flex;
}
</style>
