<template>
  <div>
    <i-header
      title="สถานะโครงการทั้งหมด"
      action-title="สร้างโครงการใหม่"
      @action-click="$router.push('/project/update')"
    />
    <Dashboard />
    <!-- <br /> -->
    <FilterInput @on-click="handleClick" :selectItems="status" />
    <!-- <br /> -->
    <i-header title="รายชื่อโครงการล่าสุด">
      <!-- <v-btn outlined color="#1E4D94" style="text-transform: none">Export file</v-btn> -->
    </i-header>
    <Datatables
      :columns="columns"
      url="/agency"
      :search-name="name"
      :selected-status="statusSelected"
    />
  </div>
</template>

<script>
import Dashboard from "./modules/dashboard";
import FilterInput from "components/filter-input/index.vue";
import Datatables from "components/datatables/index.vue";
import { columns } from "data/tables/project-columns";
import { status } from "data/status-agency";

export default {
  name: "home",
  components: {
    Dashboard,
    FilterInput,
    Datatables
  },
  data: () => ({
    columns,
    status,
    items: [
      {
        name: "test"
      }
    ],
    name: null,
    statusSelected: null
  }),
  methods: {
    handleClick({ name, selected }) {
      this.name = name;
      this.statusSelected = selected;
    }
  }
};
</script>

<style scoped>
.home-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 5px;
}
</style>
