export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "คำนำหน้าชื่อ", value: "title" },
  { text: "ชื่อ", value: "firstName" },
  { text: "สกุล", value: "lastName" },
  { text: "เบอร์โทร", value: "phone" },
  { text: "email", value: "email" },
  { text: "วันที่เริ่มต้น", value: "start", componentName: "date-custom" },
  { text: "มื้อ", value: "meal", componentName: "coupon-ets-amed" },
  { text: "สถานะ", value: "status", componentName: "status-custom-2" },
  { text: "remark", value: "remark" }
];
