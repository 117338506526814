<template>
  <div>
    <v-textarea
      v-if="!isViewing"
      class="i-text-area"
      v-model="localValue"
      :label="label"
      outlined
      dense
      style="border-radius: 6px"
      :hide-details="hideDetails"
      :required="required"
      :rules="rules"
      :type="type"
      :disabled="disabled"
    ></v-textarea>
    <div v-else class="text-view">
      {{ localValue }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {},
    value: {},
    hideDetails: {
      default: () => false
    },
    required: {
      default: () => false
    },
    rules: {},
    type: {
      default: () => "text"
    },
    disabled: {
      default: () => false
    },
    isViewing: {}
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style>
.i-text-area .v-input__slot {
  background: #fff !important;
}
.i-text-area .v-input__slot .v-label {
  color: #c7c7c7;
}
.v-text-field--outlined fieldset {
  border: 1px solid #e0e0e0;
}
</style>
